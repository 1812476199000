import gql from 'graphql-tag';

export const GAME_FIELDS = gql`
  fragment GameFields on Game {
    _id
    name
    review
    rating
    image
    platform_ids
    active
    created_at
    updated_at
    platforms{
      _id
      name
    }
    images {
      original
      sm
      md
      lg
    }
  }
`;
