import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Alert, CircularProgress } from '@mui/material';
import PayoutRequestDataGrid from '~/Components/PayoutRequest/PayoutRequestDataGrid';
import { PAYOUT_REQUEST_ADMIN_LIST } from '~/Queries/PayoutRequest/PayoutRequest.query';
import { useTitle } from '~/Context/TitleContext';



export default function PayoutRequestIndexPage() {
    const { loading, error, data } = useQuery(PAYOUT_REQUEST_ADMIN_LIST);

    const { setTitle } = useTitle();

    useEffect(() => {
      setTitle("Payouts");
    }, [setTitle]);
    
    if (loading) {
        return <CircularProgress />
    }
    if (error) {
        return <Alert severity="error">{error.message ? error.message : 'Oops! An error has ocurred...Try again later!'}</Alert>

    }
    return (
        <PayoutRequestDataGrid data={data.payoutRequestAdminList} />
    );
}


