import gql from 'graphql-tag';

import { REPORTED_ISSUE_FIELDS } from './ReportedIssue.fragment';
//#region Queries

//#endregion Queries

export const REPORTED_ISSUE_LIST = gql`
  ${REPORTED_ISSUE_FIELDS}
  query reportedIssueList{
    reportedIssueList{
      ...ReportedIssueFields
    }
  }
`;
export const REPORTED_ISSUE_LIST_PENDING = gql`
  ${REPORTED_ISSUE_FIELDS}
  query reportedIssueListPending{
    reportedIssueListPending{
      ...ReportedIssueFields
    }
  }
`;

export const REPORTED_ISSUE_FIND_BY_ID = gql`
  ${REPORTED_ISSUE_FIELDS}
  query reportedIssueFindById($id: String!){
    reportedIssueFindById(id: $id){
      ...ReportedIssueFields
    }
  }
`;


//#region Mutations
export const REPORTED_ISSUE_ADD = gql`
  ${REPORTED_ISSUE_FIELDS}
  mutation reportedIssueAdd($data: ReportedIssueInput!) {
    reportedIssueAdd(data: $data) {
      ...ReportedIssueFields
    }
  }
`;
export const REPORTED_ISSUE_UPDATE = gql`
  ${REPORTED_ISSUE_FIELDS}
  mutation reportedIssueUpdate($id: String!, $data: ReportedIssueInput!) {
    reportedIssueUpdate(id: $id,data: $data) {
      ...ReportedIssueFields
    }
  }
`;





//#endregion Mutations
