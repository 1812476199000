import { BaseModel } from "./Base.model";
export enum PaymentStatus {
    VALID = 'VALID',
    INVALID = 'INVALID',
    REFUND = 'REFUND',

}
export class Payment extends BaseModel {
    amount = 0;
    decimal_amount = 0.0;
    payment_intent_id = '';
    status: PaymentStatus = PaymentStatus.VALID;
}
