import { BaseModel } from "~/Models/Base.model";
import { Game } from "./Games.model";
import { Issue } from "./Issue.model";
import { Payment } from "./Payment.model";
import { Session } from "./Session.model";
import { User } from "./User.model";

export enum CanceledSessionStatus {
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  RESOLVED = "RESOLVED",
}

export class CanceledSession extends BaseModel {
  issue_id!: String;
  status: CanceledSessionStatus = CanceledSessionStatus.PENDING;
  user_id!: String;
  gamer_id!: String;

  issue: Issue = new Issue();
  user: User = new User();
  canceled_by_user: User = new User();
  game: Game = new Game();
  gamer: User = new User();
  session: Session = new Session();
  payment: Payment = new Payment();

  in_game_username?: String;

  game_id?: String;

  payment_intent_id?: String;

  payment_id?: String;

  charge_succeeded?: Boolean;

  session_type?: String;

  start_date?: Date;

  end_date?: Date;

  canceled_by?: String;

  canceled_by_type?: String;

  refunded?: Boolean;
}
