/*
import { useQuery } from '@apollo/client';
import { CircularProgress } from '@mui/material';

import Table, { HeadCell } from '~/Components/Table';
import { USER_FIND_BY_TYPE } from '~/Queries/Users/User.query';


const headCells: readonly HeadCell[] = [
    {
        id: '_id',
        numeric: false,
        disablePadding: true,
        label: 'ID',
    },
    {
        id: 'first_name',
        numeric: false,
        disablePadding: false,
        label: 'First name',
    },
    {
        id: 'last_name',
        numeric: false,
        disablePadding: false,
        label: 'Last name',
    }
];

export default function Users() {
    const { loading, error, data } = useQuery(USER_FIND_BY_TYPE, {
        variables: { type: 'admin' }
    });

    if (loading) {
        return <CircularProgress />
    }

    return (
        <Table
            title={'Users'}
            items={data.userFindByType}
            columns={
                headCells
            }
        ></Table>
    );
}


*/

import { useApolloClient, useQuery } from "@apollo/client";
import { CircularProgress, Fab, Tooltip } from "@mui/material";

import {
  DataGrid,
  GridToolbar,
  GridActionsCellItem,
  GridColumns,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";

import {
  USER_FIND_BY_TYPE,
  USER_DELETE,
  USER_UPDATE,
} from "~/Queries/Users/User.query";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useTitle } from "~/Context/TitleContext";

const fabStyle = {
  position: "absolute",
  bottom: 16,
  right: 16,
  zIndex: 1000,
};

interface UsersProps {
  type: string;
  active?: boolean;
}

export default function Users({ type, active = true }: UsersProps) {
  const { loading, error, data, refetch } = useQuery(USER_FIND_BY_TYPE, {
    variables: { type: type, filter: { active } },
  });

  const navigate = useNavigate();
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  const deleteItem = async (id: string) => {
    const response = await client.mutate({
      mutation: USER_DELETE,
      variables: { user_id: id },
    });

    if (response.data.userDelete) {
      refetch();
    }
  };

  const editItem = async (id: string) => {
    navigate("/users/edit/" + id);
  };

  const toggleActiveItem = async (id: string, active: boolean) => {
    const response = await client.mutate({
      mutation: USER_UPDATE,
      variables: { id, data: { active } },
    });

    if (response.data.userUpdate) {
      enqueueSnackbar("The item has been saved!", { variant: "success" });
      refetch();
    }
  };

  const { setTitle } = useTitle();
  useEffect(() => {
    // lowercase first letter
    setTitle(type.charAt(0).toUpperCase() + type.slice(1) + "s");
  }, [setTitle]);

  const headCells: GridColumns = [
    { field: "_id", headerName: "Id", hide: true },
    { field: "name", headerName: "Username" },
    { field: "email", headerName: "Email", width: 150 },
    { field: "first_name", headerName: "First name", width: 150 },
    { field: "last_name", headerName: "Last name", width: 150 },
    { field: "created_at", headerName: "Created", width: 150 },
    {
      field: "active",
      headerName: "Active",
      renderCell: (params) => {
        if (params.row.active) {
          return (
            <GridActionsCellItem
              icon={
                <Tooltip title="Users is active. Click to inactivate user">
                  <ToggleOffIcon
                    sx={{
                      color: "success.main",
                    }}
                  />
                </Tooltip>
              }
              title="inactive"
              label="Inactive user"
              onClick={() => toggleActiveItem(params.row._id, false)}
            />
          );
        }
        return (
          <GridActionsCellItem
            icon={
              <Tooltip title="Users is inactive. Click to activate user">
                <ToggleOnIcon
                  sx={{
                    color: "error.main",
                  }}
                />
              </Tooltip>
            }
            label="Active user"
            onClick={() => toggleActiveItem(params.row._id, true)}
          />
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      getActions: ({ id, getValue }) => {
        const _id = getValue(id, "_id") as string;
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            onClick={() => editItem(_id)}
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => deleteItem(_id)}
          />,
        ];
      },
    },
  ];

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <div style={{ height: "70vh", width: "100%" }}>
      <DataGrid
        getRowId={(row) => row._id}
        rows={data.userFindByType}
        columns={headCells}
        components={{
          Toolbar: GridToolbar,
        }}
      ></DataGrid>

      <Fab
        color="primary"
        aria-label="add"
        sx={fabStyle}
        onClick={() => navigate("/users/add")}
      >
        <AddIcon />
      </Fab>
    </div>
  );
}
