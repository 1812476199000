import { Navigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { authUserAtom } from "~/Recoil/Auth.recoil";


const LogoutPage = () => {


    const [, setAuthUser] = useRecoilState(authUserAtom);


    setAuthUser(null);
    localStorage.clear();


    return (
        <Navigate to="/" />

    );
};

export default LogoutPage;
