import gql from 'graphql-tag';

export const GAMER_FIELDS = gql`
  fragment GamerFields on User {
    _id
    name
    password
    email
    first_name
    last_name
    phone
    sex
    url
    company_name
    image
    about_me
    type
    review
    active
    created_at
    updated_at
    favorite
    images {
      original
      sm
      md
      lg
      grayscale_sm
      grayscale_md
      grayscale_lg
    }
    games{
      game{
        name
      }
      platform{
        name
      }
      
    }
    
    covers {
      original
      cover
    }
    #pricing
    price_off_stream
    price_on_stream
    profit_percent_off_stream
    profit_percent_on_stream
  }
`;
