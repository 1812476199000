import React from 'react';

import { DataGrid, GridToolbar, GridActionsCellItem, GridColumns, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { PayoutRequest } from '~/Models/PayoutRequest.model';







interface PayoutRequestDataGridProps {
    data: Array<PayoutRequest>
}

export default function PayoutRequestDataGrid({ data }: PayoutRequestDataGridProps) {

    // Navigation
    const navigate = useNavigate();


    // On Click Edit
    const onClickEdit = (params: GridRowParams) => {
        navigate(`/payout-requests/edit/${params.id}`);

    }
    // Columns
    const headCells: GridColumns = [
        { field: '_id', headerName: 'Id', hide: true },
        { field: 'status', headerName: 'Status' },
        { field: 'amount', headerName: 'Amount', valueGetter: ({ row }) => "$"+row.decimal_amount },
        {
            field: 'username', headerName: 'Username',
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.user.name}`,
        },
        {
            field: 'email', headerName: 'Email',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.user.email}`,
        },
        {
            field: 'fullName', headerName: 'Full name',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.user.first_name || ''} ${params.row.user.last_name || ''}`,
        },
        { field: 'created_at', headerName: 'Created', width: 180 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={() => onClickEdit(params)} />
            ],
        }
    ];


    return (
        <div style={{ height: '70vh', width: '100%' }}>

            <DataGrid

                getRowId={(row) => row._id}
                rows={data}
                columns={
                    headCells
                }
                components={{
                    Toolbar: GridToolbar,
                }}
            ></DataGrid>
        </div>
    );
}


