import { useQuery } from "@apollo/client";
import { Alert, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import CanceledSessionForm from "~/Components/CanceledSession/CanceledSessionForm";
import { useTitle } from "~/Context/TitleContext";
import { CanceledSession } from "~/Models/CanceledSession.model";
import { CANCELED_SESSION_FIND_BY_ID } from "~/Queries/CanceledSession/CanceledSession.query";

const CanceledSessionEditPage = () => {
  const { id } = useParams();

  const { data, loading, error, refetch } = useQuery(
    CANCELED_SESSION_FIND_BY_ID,
    {
      variables: { id: id },
    }
  );

  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Canceled Sessions / Edit");
  }, [setTitle]);

  const onRefetch = () => {
    refetch();
  };
  if (error) {
    return (
      <Alert severity="error">
        {error.message
          ? error.message
          : "Oops! An error has ocurred...Try again later!"}
      </Alert>
    );
  }
  return loading ? (
    <CircularProgress />
  ) : (
    <CanceledSessionForm
      refetch={onRefetch}
      data={data.canceledSessionFindById as CanceledSession}
    />
  );
};

export default CanceledSessionEditPage;
