import { BaseModel } from '~/Models/Base.model';
import { User } from './User.model';
export enum RateSessionStatus {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    DISAPPROVED = 'DISAPPROVED'
  }
  
export class RateSession extends BaseModel {
    _id = ''
    session_id = ''
    user_id = ''
    gamer_id = ''
    rate = 0
    tip  = 0;
    message = ''
    status = '' 
    user: User = new User();
    gamer: User = new User();
}
