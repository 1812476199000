import React, { useEffect } from "react";

import { useQuery } from "@apollo/client";
import { Alert, CircularProgress } from "@mui/material";
import { RATE_SESSION_LIST_PENDING } from "~/Queries/RateSession/RateSession.query";
import RateSessionDataGrid from "~/Components/RateSession/RateSessionDataGrid";
import { useTitle } from "~/Context/TitleContext";

export default function RateSessionPendingPage() {
  const { loading, error, data } = useQuery(RATE_SESSION_LIST_PENDING);

  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Rate Sessions / Pending");
  }, [setTitle]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return (
      <Alert severity="error">
        {error.message
          ? error.message
          : "Oops! An error has ocurred...Try again later!"}
      </Alert>
    );
  }

  return <RateSessionDataGrid data={data.rateSessionListPending} />;
}
