
import React, { useCallback, useEffect, useState } from 'react';
import { Avatar, Button, Card, CardContent, CardHeader, Fab, Stack, Typography } from '@mui/material';
import TimeUtility from '~/Utilities/Time/Time.utility';
import AvatarUtility from '~/Utilities/Avatar/Avatar.utility';
import { useSnackbar } from 'notistack';
import { useApolloClient } from '@apollo/client';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useNavigate } from 'react-router-dom';
import { Suggestion } from '~/Models/Suggestion.model';
import { SUGGESTION_DELETE } from '~/Queries/Suggestion/Suggestion.query';

const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
    zIndex: 1000
};


interface SuggestionFormPorps {
    data: Suggestion,
    refetch?: () => void
}

export default function SuggestionForm(props: SuggestionFormPorps) {
    // Navigation
    const navigate = useNavigate();
    // Rate Session
    const [suggestion, setSuggestion] = useState<Suggestion | null>(null)
    // Props
    const { data } = props;
    // Client
    const client = useApolloClient();
    // Snack
    const { enqueueSnackbar } = useSnackbar();

    const onClickDelete = async (id: string) => {

        if (window.confirm("Do you really want to delete this item?")) {

            const response = await client.mutate(
                {
                    mutation: SUGGESTION_DELETE,
                    variables: { id }
                }
            )

            const suggestionDeleted = response.data.suggestionDelete || null;

            if (suggestionDeleted) {
                enqueueSnackbar('The item has been deleted!', { variant: 'success' });
                navigate(-1);
            } else {
                enqueueSnackbar('Error, please try again!', { variant: 'error' });
            }
        }
    }

    const fetchData = useCallback(() => {
        setSuggestion({ ...data })
    }, [data])
    useEffect(() => {
        fetchData()
    }, [data, fetchData])


    if (!suggestion) {
        return null;
    }


    // Avatar
    const avatar = AvatarUtility.getAvatar(suggestion.user.avatar_sku)
    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: 'primary.main', color: '#fff' }} src={avatar ? avatar.image : ''} >
                        {`${suggestion.user.first_name.charAt(0)}${suggestion.user.last_name.charAt(0)}`}
                    </Avatar>
                }
                title={`${suggestion.user.first_name} ${data.user.last_name}`}
                subheader={<Typography>{`Owrlee user since ${TimeUtility.format(suggestion.user.created_at, 'MMMM YYYY')}`}</Typography>}
            />

            <CardContent>
                {/* Status */}
                <Stack >
                    <Typography variant="h5" >
                        Suggestion:
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {`${suggestion.suggestion} `}
                    </Typography>
                </Stack>
                {/* Created */}
                <Stack mt={2}>
                    <Typography variant="h5" >
                        Created:
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {`${suggestion.created_at} `}
                    </Typography>
                </Stack>


                <Stack spacing={2} mt={4} direction="row">
                    <Button onClick={() => onClickDelete(suggestion._id)} variant={'contained'}>Delete</Button>
                </Stack>

                <Fab color="primary" aria-label="add" sx={fabStyle} onClick={() => navigate(-1)}>
                    <NavigateBeforeIcon />
                </Fab>
            </CardContent>

        </Card>
    )
}