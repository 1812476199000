/* eslint-disable sort-keys */
import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';

// import { WebSocketLink } from '@apollo/client/link/ws';

import { createUploadLink } from 'apollo-upload-client';

import Config from '~/Config/Config';

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true
  },
  attempts: {
    max: 5,
    retryIf: (error: any, _operation: any) => !!error
  }
});

const httpLink = createUploadLink({
  uri: Config.API_URL,
});

const authMiddleware = setContext(async (operation, { headers }) => {
  const token = await localStorage.getItem('@token');
  // console.log(`Bearer ${token}`);
  return {
    headers: {
      ...headers,
      Authentication: token ? `Bearer ${token}` : ''
    }
  };
});

const errorLink = onError((error) => {
  console.log('error');
  const { graphQLErrors, forward, operation, networkError } = error;
  // To retry on network errors, we recommend the RetryLink
  // instead of the onError link. This just logs the error.
  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
  if (graphQLErrors) {
    console.log('on error', { graphQLErrors });
    for (const err of graphQLErrors) {
      // handle errors differently based on its error code
      const code = err.extensions ? err.extensions.code : 'INTERNAL_SERVER_ERROR';
      switch (code) {
        case 'UNAUTHENTICATED':
          // old token has expired throwing AuthenticationError,
          // one way to handle is to obtain a new token and
          // add it to the operation context
          // const headers = operation.getContext().headers;

          //console.warn('TODO: UNAUTHENTICATED')

          // Now, pass the modified operation to the next link
          // in the chain. This effectively intercepts the old
          // failed request, and retries it with a new token
          return forward(operation);
        case 'FORBIDDEN':
          // return forward(operation);
          // console.log('error FORBIDDEN')
          // UserStoreActions.isLoggedIn(false);
          return forward(operation);
        //return null;

        // handle other errors
        case 'INTERNAL_SERVER_ERROR':
          // return forward(operation);
          // console.log('error FORBIDDEN', userStore.state.isLoggedIn )
          // UserStoreActions.isLoggedIn(false);
          // return null;
          return forward(operation);
      }
    }
  }
});
// Auth link
// const authLink = concat(authMiddleware, httpLink);
// Ws link
// const wsLink = new WebSocketLink({
//   uri: Environment.wsUrl,
//   options: {
//     reconnect: true,
//     connectionParams: {
//       // authToken: localStorage.getItem(AUTH_TOKEN),
//     }
//   }
// });
/**
 * https://www.apollographql.com/docs/react/data/subscriptions/
 *
 * Now create a new WebSocketLink that represents the WebSocket connection.
 * Use split for proper “routing” of the requests and update the constructor call of ApolloClient
 * like so:
 */
// const link = split(
//   ({ query }) => {
//     const operation = getMainDefinition(query);
//     return operation.kind === 'OperationDefinition' && operation.operation === 'subscription';
//   },
// );

// Cache
const appCache = new InMemoryCache();

// const client = new ApolloClient({
//   link: ApolloLink.from([retryLink, wsLink, authLink]),
//   cache: appCache
// });


const client = new ApolloClient({
  link: from([retryLink, errorLink, authMiddleware, httpLink]),
  cache: appCache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },

  }

  });
export default client;
