import { useApolloClient, useQuery } from "@apollo/client";
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import moment from "moment";
import { CSVLink } from "react-csv";

import { useCallback, useEffect, useState } from "react";
import { ADMIN_DASHBOARD } from "~/Queries/AdminDashboard/AdminDashboard";
import { PAYMENT_HISTORY } from "~/Queries/Payment/Payment.query";
import { useTitle } from "~/Context/TitleContext";

const StatisticCard = (props: any) => (
  <Grid item md={3} sm={6} xs={6}>
    <Card variant="outlined">
      <CardContent>
        <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
          {props.title}
        </Typography>
        <Typography variant="h5" component="div">
          {props.value}
        </Typography>
      </CardContent>
    </Card>
  </Grid>
);

export default function HomePage() {
  const client = useApolloClient();
  const { data, error, loading } = useQuery(ADMIN_DASHBOARD);
  const [csvData, setCsvData] = useState([]);

  const { setTitle } = useTitle();
  useEffect(() => {
    setTitle("Dashboard");
  }, [setTitle]);

  const buildPaymentsCSV = useCallback(async () => {
    //Session Details								User Details					Gamer Details
    //Activity	Date Purchased/Cancelled	On-Stream or Off-Stream?	Booking Fee/Tip Amount	Total Fee (Including Stripe Fee + Taxes)	Game	Session Date	Session Time	First Name	Last Name	Email Address	Gender	Date of Birth	Username	First Name	Last Name	% Earning (On-Stream)	% Earning (Off-Stream)

    const paymentHistoryData = await client.query({
      query: PAYMENT_HISTORY,
    });

    const paymentArray = paymentHistoryData.data.paymentHistory.map(
      (item: any) => {
        const session = item.session || item.canceled_session;
        return {
          activity:
            item.payment_type === "REFUND"
              ? "Cancellation"
              : item.description === "USER TIP"
              ? "Tip"
              : "Purchase",
          date: item.created_at,
          canceled_by: session ? session.canceled_by_type : "",
          session_type: session ? session.session_type : "",
          amount: item.amount / 100,
          total_fee: item.to_app_amount,
          game: session ? session.game.name : "",
          session_date: session
            ? moment(session.start_date).format("MM-DD-YYYY")
            : "",
          session_time: session
            ? moment(session.start_date).format("h:mmA")
            : "",

          user_first_name: item.user?.first_name || "",
          user_last_name: item.user?.last_name || "",
          user_email: item.user?.email || "",
          user_gender: item.user?.sex || "",
          user_birth_date: item.user?.birth_date
            ? moment(item.user.birth_date).format("MM-DD-YYYY")
            : "",
          gamer_name: session ? session.gamer?.name : "",
          gamer_first_name: session ? session.gamer?.first_name : "",
          gamer_last_name: session ? session.gamer?.last_name : "",
          profit_percent_on_stream: session
            ? session.gamer?.profit_percent_off_stream
            : "",
          profit_percent_off_stream: session
            ? session.gamer?.profit_percent_off_stream
            : "",
        };
      }
    );
    setCsvData(paymentArray);
    console.log({ paymentArray });
  }, [client]);

  useEffect(() => {
    buildPaymentsCSV();
  }, [buildPaymentsCSV, setCsvData]);
  if (loading) {
    return <CircularProgress />;
  }
  return (
    <Grid container spacing={3} padding={2}>
      <Grid item md={12} sm={12} xs={12}>
        <Typography variant="h1">Statistics</Typography>
      </Grid>

      <StatisticCard
        title="Amount of gamers "
        value={data?.adminDashboard?.gamers_in_app}
      />
      <StatisticCard
        title="Amount of users"
        value={data?.adminDashboard?.users_in_app}
      />
      <StatisticCard
        title="AVG Reservations per User"
        value={Math.round(
          data?.adminDashboard?.today_total_reservations /
            data?.adminDashboard?.users_in_app
        )}
      />
      <StatisticCard
        title="AVG Future Reservations Purchased"
        value={Math.round(
          data?.adminDashboard?.future_total_reservations /
            data?.adminDashboard?.users_in_app
        )}
      />
      <StatisticCard
        title="Total Reservation to Date"
        value={
          data?.adminDashboard.today_total_reservations +
          data?.adminDashboard.future_total_reservations
        }
      />
      <StatisticCard
        title="Completed Sessions"
        value={data?.adminDashboard?.today_total_reservations}
      />
      <StatisticCard
        title="On-Stream Purchases"
        value={data?.adminDashboard?.on_stream_purchases}
      />
      <StatisticCard
        title="Off-Stream Purchases"
        value={data?.adminDashboard?.off_stream_purchases}
      />
      <StatisticCard
        title="Total Future Reservations"
        value={data?.adminDashboard?.future_total_reservations}
      />
      <StatisticCard
        title="Canceled Reservations"
        value={
          data?.adminDashboard?.canceled_user_sessions +
          data?.adminDashboard?.canceled_gamer_sessions
        }
      />

      <StatisticCard
        title="Canceled Reservations by Users"
        value={data?.adminDashboard?.canceled_user_sessions}
      />
      <StatisticCard
        title="Canceled Reservations by Gamers"
        value={data?.adminDashboard?.canceled_gamers_sessions || 0}
      />

      <StatisticCard
        title="Gross Revenue per Session"
        value={
          "$" + Math.round(data?.adminDashboard?.revenue_average * 100) / 100
        }
      />
      <StatisticCard
        title="Gross Revenue by Month Last 3 Months"
        value={"$" + data?.adminDashboard?.revenue_3_months}
      />
      <StatisticCard
        title="Gross Revenue by Month Last 6 Months"
        value={"$" + data?.adminDashboard?.revenue_6_months}
      />
      <StatisticCard
        title="Gross Revenue by Month Last 12 Months"
        value={"$" + data?.adminDashboard?.revenue_12_months}
      />
      <StatisticCard
        title="Most Purchased Gamer"
        value={data?.adminDashboard?.most_purchased_gamer.display_name}
      />
      <StatisticCard
        title="Most Purchased Game"
        value={data?.adminDashboard?.most_purchased_game.name}
      />

      <Grid item xs={12} sx={{ textAlign: "right" }}>
        <Button variant="contained">
          <CSVLink data={csvData} style={{ color: "inherit" }}>
            Download Payment History
          </CSVLink>
        </Button>
      </Grid>
    </Grid>
  );
}
