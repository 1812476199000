import React, { useCallback } from 'react';

import { DataGrid, GridToolbar, GridColumns, GridValueGetterParams, GridPreProcessEditCellProps, GridRowModel } from '@mui/x-data-grid';


import { User } from '~/Models/User.model';
import PricingUtility from '~/Utilities/Pricing/Pricing.utility';
import { useSnackbar } from 'notistack';
import { resolve } from 'node:path/win32';
import { useApolloClient } from '@apollo/client';
import { GAMER_UPDATE_PRICING } from '~/Queries/Gamer/Gamer.query';


interface GamerPricingDataGridProps {
    data: Array<User>
}

export default function GamerPricingDataGrid({ data }: GamerPricingDataGridProps) {

    // Messages
    const { enqueueSnackbar } = useSnackbar();

    // Client 
    const client = useApolloClient();

    // Validate number not negative
    const validateNumber = (params: GridPreProcessEditCellProps) => {
        if (params.props.value) {
            const hasError = params.props.value < 0;
            return { ...params.props, error: hasError };
        }
        return { ...params.props };
    }
    // Columns
    const headCells: GridColumns = [
        { field: '_id', headerName: 'Id', hide: true, hideable: false, filterable: false },

        {
            field: 'fullName', headerName: 'Full name',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.first_name || ''} ${params.row.last_name || ''}`,
        },
        {
            field: 'username', headerName: 'Username',
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.name}`,
        },
        //  Price
        { field: 'price_on_stream', type: 'number', headerName: 'On-Stream', editable: true, minWidth: 110, preProcessEditCellProps: validateNumber },
        { field: 'price_off_stream', type: 'number', headerName: 'Off-Stream', editable: true, minWidth: 110, preProcessEditCellProps: validateNumber },
        // Calculated
        {
            field: 'stripe_on_stream', type: 'number', headerName: 'Stripe On-Stream', editable: false, minWidth: 140,
            valueGetter: (params: GridValueGetterParams) =>
                `${PricingUtility.calculateProfitStripe(params.row.price_on_stream)}`,
        },
        {
            field: 'stripe_off_stream', type: 'number', headerName: 'Stripe Off-Stream', editable: false, minWidth: 140,
            valueGetter: (params: GridValueGetterParams) =>
                `${PricingUtility.calculateProfitStripe(params.row.price_off_stream)}`
        },
        // Percent
        { field: 'profit_percent_on_stream', type: 'number', headerName: '% On-Stream', editable: true, minWidth: 110, preProcessEditCellProps: validateNumber },
        { field: 'profit_percent_off_stream', type: 'number', headerName: '% Off-Stream', editable: true, minWidth: 110, preProcessEditCellProps: validateNumber },

    ];

    const processRowUpdate = useCallback(
        (newRow: GridRowModel, oldRow: GridRowModel) =>
            new Promise<GridRowModel>(async (resolve, reject) => {
                if (JSON.stringify(newRow) !== JSON.stringify(oldRow)) {

                    try {
                        const updateData = {
                            price_off_stream: newRow.price_off_stream,
                            price_on_stream: newRow.price_on_stream,
                            profit_percent_off_stream: newRow.profit_percent_off_stream,
                            profit_percent_on_stream: newRow.profit_percent_on_stream
                        }
                        const response = await client.mutate({
                            mutation: GAMER_UPDATE_PRICING, variables: {
                                id: newRow._id,
                                data: updateData
                            }
                        })
                        const updatedGamer = response.data.gamerUpdatePricing || {}
                        if (updatedGamer._id) {
                            enqueueSnackbar(`Gamer ${updatedGamer.first_name || ''} ${updatedGamer.last_name || ''} update successfully`, { variant: 'success' });
                            resolve(newRow);
                        } else {
                            enqueueSnackbar('Error submiting the item. Please try again.', { variant: 'error' });
                            resolve(oldRow);
                        }

                    } catch (error: any) {
                        const message = error.message ? `: ${error.message}` : ' submiting the item. Please try again'
                        enqueueSnackbar(`Error${message}`, { variant: 'error' });
                        resolve(oldRow);
                    }
                }
                resolve(oldRow)


            }),
        [client, enqueueSnackbar],
    );






    return (
        <div style={{ height: '70vh', width: '100%' }}>

            <DataGrid
                editMode="row"
                getRowId={(row) => row._id}
                rows={data}
                columns={
                    headCells
                }
                components={{
                    Toolbar: GridToolbar,
                }}
                // processRowUpdate={processRowUpdate}
                processRowUpdate={processRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}


            ></DataGrid>
        </div>
    );
}


