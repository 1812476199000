import gql from 'graphql-tag';

export const PAYOUT_REQUEST_FIELDS = gql`
  fragment PayoutRequestFields on PayoutRequest {
    _id
    amount
    status
    decimal_amount
    reason
    created_at
    user{
      _id
      email
      name
      first_name
      last_name
      images {
          original
          sm
          md
          lg
          grayscale_sm
          grayscale_md
          grayscale_lg
      }
      #bank information
      account_holder_name
      account_holder_type
      account_type
      bank_name
      account_number
      account_routing_number
    }
  }
`;
