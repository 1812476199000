import { useQuery } from "@apollo/client";
import { CircularProgress, Avatar, Fab } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  DataGrid,
  GridToolbar,
  GridActionsCellItem,
  GridColumns,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

import { GAME_DELETE, GAME_LIST } from "~/Queries/Games/Game.query";
import ListPage from "~/Components/ListPage";
import {
  PLATFORM_DELETE,
  PLATFORM_LIST,
} from "~/Queries/Platform/Platform.query";
import { useEffect } from "react";
import { useTitle } from "~/Context/TitleContext";

const headCells: GridColumns = [
  { field: "_id", headerName: "Id", hide: true },

  { field: "name", headerName: "Name" },

  { field: "created_at", headerName: "Created", width: 180 },
];

export default function PlatformPage() {
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Platforms");
  }, [setTitle]);
  return (
    <ListPage
      query={PLATFORM_LIST}
      headCells={headCells}
      deleteQuery={PLATFORM_DELETE}
      addActionUrl={"/platforms/add"}
      editActionUrl={"/platforms/edit"}
    />
  );
}
