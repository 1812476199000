import gql from 'graphql-tag';

export const REPORTED_ISSUE_FIELDS = gql`
  fragment ReportedIssueFields on ReportedIssue {
    _id
    status
    issue_id
    user_id
    description
    active
    created_at
    updated_at
    issue{
      type
      name
    }
    user{
      _id
      email
      name
      first_name
      last_name
      avatar_sku
      images {
          original
          sm
          md
          lg
          grayscale_sm
          grayscale_md
          grayscale_lg
      }
    }
    session{
        _id
        user_id
        gamer_id
        game_id
        start_date
        end_date
        created_at
        updated_at
        session_type
        gamer{
          _id
          email
          name
          first_name
          last_name
          avatar_sku
          images {
            original
            sm
            md
            lg
            grayscale_sm
            grayscale_md
            grayscale_lg
          }
       }
       payment{
          _id
          amount
          decimal_amount
          status
          payment_intent_id
       }
    }
  }
`;
