import { Alert, Checkbox, Divider, FormControl, FormControlLabel, FormGroup, FormHelperText, Grid, TextField, Typography } from "@mui/material";
import Form from "~/Components/Form";
import { GAME_ADD, GAME_UPDATE } from "~/Queries/Games/Game.query";
import ImageInput from "./ImageInput";

import { useQuery } from "@apollo/client";
import { PLATFORM_LIST } from "~/Queries/Platform/Platform.query";
import { Platform } from "~/Models/Platform.model";

import * as Yup from 'yup';


class GameInputModel {
    name?: string;
    platform_ids?: any;
    file?: File;
}

const defaultData: GameInputModel = {
    //name: "",
    //active: true
}



interface PlatformCheckboxProps {
    platform: Platform,
    checked: [String],
    handleChange: (e: React.ChangeEvent<any>) => void
}

function PlatformCheckbox({ platform, checked, handleChange }: PlatformCheckboxProps) {
    const isChecked =  checked && checked.find(c => c === platform._id);
    const defaultChecked = isChecked && isChecked.length > 0;
    return (
        <Grid item md={4} xs={12} >
            <FormGroup>
                <FormControlLabel control={<Checkbox
                    defaultChecked={defaultChecked}
                    name={`platform_ids`} value={platform._id.toString()}
                    onChange={(e) => handleChange(e)} />} label={platform.name} />
            </FormGroup>
        </Grid>
    )
}
interface GameFormInterface {
    id?: string | null;
    data?: GameInputModel,
}

export default function GameForm(props: GameFormInterface) {
    // Validation
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is empty'),
    })

    // Platforms
    const { data: dataPlatforms, loading: loadingPlatform, error: errorPlatform } = useQuery(PLATFORM_LIST);
    let platforms = new Array<Platform>();
    if (!loadingPlatform && !errorPlatform && dataPlatforms) {

        platforms = dataPlatforms.platformList;
    }

    if (errorPlatform) {
        return <Alert severity="error">{errorPlatform.message ? errorPlatform.message : 'Oops! An error has ocurred...Try again later!'}</Alert>

    }

    return (
        <Form
            validationSchema={validationSchema}
            beforeSubmit={(result: any, props, values: any) => {
                return {
                    name: values.name,
                    active: values.active,
                    file: values.file,
                    platform_ids: values.platform_ids
                };
            }}
            model={GameInputModel} createMutation={GAME_ADD} updateMutation={GAME_UPDATE} backLink="/games" id={props.id} data={props.data || defaultData} >
            {({ errors, handleBlur, setFieldValue, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <Grid container spacing={2} >
                    {/* Image */}
                    <Grid item md={12} xs={12} >
                        <ImageInput onChange={(file) => setFieldValue("file", file as File)} images={values.images} />
                    </Grid>
                    {/* Name */}
                    <Grid item md={12} xs={12} >
                        <FormControl fullWidth >

                            <TextField
                                label="Name"
                                name="name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                            />
                            {errors.name && (
                                <FormHelperText error id="standard-weight-helper-text-email-login">
                                    {errors.name}
                                </FormHelperText>
                            )}
                        </FormControl>
                    </Grid>

                    <Grid item md={12} xs={12} >
                        <Typography variant="h1">Platforms</Typography>
                    </Grid>
                    {/* Platforms */}
                    {platforms && platforms.map(platform => (
                        <PlatformCheckbox checked={values.platform_ids} key={platform._id} platform={platform} handleChange={handleChange} />
                    ))}
                    <Grid md={12} xs={12} >
                        <FormControl fullWidth >
                            <Divider variant="middle" />
                        </FormControl>
                    </Grid>
                    {/* Active */}
                    <Grid item md={12} xs={12} >
                        <FormControl fullWidth variant="standard">
                            <FormControlLabel control={<Checkbox defaultChecked
                                name="active"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.active}
                            />} label="Active" />
                        </FormControl>
                    </Grid>
                </Grid>

            )
            }
        </Form >
    )
}