import React from 'react';
import { useApolloClient } from '@apollo/client';
import { Fab, Box, FormControl, FormHelperText, TextField, Button, Chip, Avatar, Grid, Checkbox, FormControlLabel, Stack, InputLabel, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

// third party
import * as Yup from 'yup';
import { Formik } from 'formik';


import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import { HOME_CAROUSEL_ITEM_ADD, HOME_CAROUSEL_ITEM_UPDATE } from '~/Queries/HomeCarouselItems/HomeCarouselItems.query';
import { HomeCarouselItem } from '~/Models/HomeCarouseltem.model';

import { APP_MOBILE_URLS } from '~/Constants/App.constants';





const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
    zIndex: 1000
};

const Input = styled('input')({
    display: 'none',
});

// interface State {
//     title: string;
//     subtitle: string;
//     file: File | null;
//     previewFileData?: string | null;
//     button_text?: string;
//     subtitle_color?: string;
//     is_internal?: boolean;
//     link?: string;
//     submit: any;

// }

interface HomeCarouselItemFormInterface {
    id?: string | null;
    data?: HomeCarouselItem
}



export default function HomeCarouselItemForm({ id, data = {
    _id: "",
    order: 0,
    name: "",
    title: "",
    subtitle: "",
    button_text: "",
    link: "",
    image: "",
    is_internal: false,
    active: true,
    images: [],
    created_at: new Date(),
    updated_at: new Date()
} }: HomeCarouselItemFormInterface) {
    const navigate = useNavigate();
    const [previewFileData, setPreviewFileData] = React.useState<string>(data.images && data.images.length > 0 ? data.images[0].md as string : "");

    const validExtensions = ['.png', '.jpg', '.jpeg'];

    const validationSchema = Yup.object().shape({
        file: id ? Yup.string() : Yup.string().required('Image is empty'),
        order: Yup.number().required('Order is empty'),
        name: Yup.string().max(100).required('Name is empty'),
        title: Yup.string().max(100).required('Title is empty'),
        subtitle: Yup.string().max(100),
        button_text: Yup.string().max(100).required('Button text is empty'),
        link: Yup.string().max(100).required('Link is empty')
    })

    const client = useApolloClient();



    const onSubmit = async (values: any, { setErrors, setStatus, setSubmitting }: any) => {
        try {
            let saved = false;
            const data = {
                order: values.order,
                name: values.name,
                title: values.title,
                subtitle: values.subtitle,
                button_text: values.button_text,
                subtitle_color: values.subtitle_color,
                link: values.link,
                is_internal: values.is_internal,
                file: values.file

            }
            if (id) {
                const response = await client.mutate({
                    mutation: HOME_CAROUSEL_ITEM_UPDATE,
                    variables: {
                        id,
                        data
                    }
                });
                saved = response.data.homeCarouselItemUpdate;
            } else {
                const response = await client.mutate({
                    mutation: HOME_CAROUSEL_ITEM_ADD,
                    variables: {
                        data
                    }
                });
                saved = response.data.homeCarouselItemAdd;
            }
            if (saved) {
                await setStatus({ success: true });
                await setSubmitting(false);
                navigate('/home-carousel-items/', { state: { refresh: true } })
            } else {
                setStatus({ success: false });
                setErrors({ submit: 'Error submiting the item. Please try again.' });
                setSubmitting(false);
            }
        }
        catch (err: any) {

            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);

        }


        setStatus({ success: false });
        setSubmitting(false);

    }

    const handleUpload =
        (prop: keyof HomeCarouselItem, setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) => (event: React.ChangeEvent<HTMLInputElement>) => {

            if (event.target.files) {
                const reader = new FileReader();
                const file = event.target.files[0];
                // Validate Extension
                const extension = '.' + file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase();
                const valid = extension.indexOf(extension);

                if (valid === -1) {
                    alert('Extension not allowed');
                    return null;
                }

                reader.readAsDataURL(file); // read file as data url
                reader.onload = (fileEvent: ProgressEvent<FileReader>) => { // called once readAsDataURL is completed
                    if (fileEvent.loaded && fileEvent.target) {
                        setPreviewFileData(fileEvent.target.result as string);
                    }

                }

                setFieldValue('file', file);

            }

        };




    return (
        <Box>


            <Formik
                initialValues={data}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ errors, handleBlur, setFieldValue, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }} mt={2}>
                            <Grid container spacing={2}>
                                {/* Image */}
                                <Grid item md={12} container spacing={2}>
                                    {previewFileData && (
                                        <>
                                            <Grid item xs={12} md={'auto'}>
                                                {previewFileData && <Avatar src={previewFileData} variant="square" sx={{ width: 250, height: 250 }} />}
                                            </Grid>
                                            <Grid item xs={12} md={'auto'} display={'flex'} alignItems={'flex-end'}>
                                                {values.file && <Chip sx={{ ml: 2 }} label={values.file?.name} variant="filled" />}
                                            </Grid>
                                        </>)}
                                </Grid>
                                <Grid item md={12} xs={12} >
                                    <label htmlFor="contained-button-file">
                                        <Input name="file" id="contained-button-file" type="file" onBlur={handleBlur}
                                            onChange={handleUpload('file', setFieldValue)}
                                            accept={validExtensions.join(',')}
                                        />
                                        <Button variant="contained" component="span" color="secondary">
                                            Select image
                                        </Button>
                                    </label>
                                    <FormControl fullWidth >
                                        {errors.file && (
                                            <FormHelperText error >
                                                {errors.file}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                    <FormHelperText >
                                        {`Recommended size 1280x1920`}
                                    </FormHelperText>
                                </Grid>
                                {/* Order */}
                                <Grid item md={12} xs={12} >
                                    <FormControl fullWidth margin="dense">
                                        <TextField
                                            fullWidth
                                            label="Order"
                                            name="order"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.order}
                                            type={'number'}


                                        />
                                        {touched.order && errors.order && (
                                            <FormHelperText error>
                                                {errors.order}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                {/* Name */}
                                <Grid item md={12} xs={12} >
                                    <FormControl fullWidth margin="dense">
                                        <TextField
                                            fullWidth
                                            label="Name"
                                            name="name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.name}
                                            inputProps={{ maxLength: 100 }}


                                        />
                                        {touched.name && errors.name && (
                                            <FormHelperText error>
                                                {errors.name}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                {/* Title */}
                                <Grid item md={12} xs={12} >
                                    <FormControl fullWidth margin="dense">
                                        <TextField
                                            fullWidth
                                            label="Title"
                                            name="title"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.title}
                                            inputProps={{ maxLength: 100 }}
                                        />
                                        {touched.title && errors.title && (
                                            <FormHelperText error>
                                                {errors.title}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                {/* Sub Title */}
                                <Grid item md={12} xs={12} >
                                    <FormControl fullWidth margin="dense">
                                        <TextField
                                            fullWidth
                                            label="Subtitle"
                                            name="subtitle"
                                            value={values.subtitle}
                                            id="outlined-start-adornment"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{ maxLength: 100 }}

                                        />
                                        {touched.subtitle && errors.subtitle && (
                                            <FormHelperText error id="standard-weight-helper-text-email-login">
                                                {errors.subtitle}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                {/* Button Text */}
                                <Grid item md={12} xs={12} >
                                    <FormControl fullWidth margin="dense">
                                        <TextField
                                            fullWidth
                                            label="Button Text"
                                            name="button_text"
                                            value={values.button_text}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            inputProps={{ maxLength: 100 }}
                                        />
                                        {touched.button_text && errors.button_text && (
                                            <FormHelperText error >
                                                {errors.button_text}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                {/* Is External */}
                                <Grid item md={12} xs={12} >
                                    <FormControl fullWidth margin="dense">
                                        <FormControlLabel control={
                                            <Checkbox
                                                name="is_internal"
                                                value={values.is_internal}
                                                checked={values.is_internal}
                                                onBlur={handleBlur}
                                                onChange={(e) => { setFieldValue('link', ''); handleChange(e) }}
                                            />
                                        }
                                            label="Is internal app link?" />
                                    </FormControl>
                                </Grid>
                                {/* Link */}
                                {values.is_internal &&

                                    <Grid item md={12} xs={12} >
                                        <FormControl fullWidth margin="dense">
                                            <InputLabel id="link-internal-label">Internal Links</InputLabel>
                                            <Select
                                                name="link"
                                                value={values.link}
                                                label="Internal Links"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            >
                                                {APP_MOBILE_URLS.map((link) => (<MenuItem key={link.value} value={link.value}>{link.name}</MenuItem>))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                }
                                {/* Link Exterma; */}
                                {!values.is_internal &&
                                    <Grid item md={12} xs={12} mt="20px">
                                        <FormControl fullWidth margin="dense">
                                            <TextField
                                                fullWidth
                                                label="Link"
                                                name="link"
                                                value={values.link}
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                            />
                                        </FormControl>
                                    </Grid>
                                }
                            </Grid>
                            {/* Error Link */}
                            <Grid item md={12} xs={12} >
                                <FormControl fullWidth>
                                    {touched.link && errors.link && (
                                        <FormHelperText error>
                                            {errors.link}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                            </Grid>
                        </Box>

                        <Box sx={{ mt: 2 }}>
                            <FormControl fullWidth margin="dense">
                                <Button
                                    disableElevation
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Submit
                                </Button>
                            </FormControl>
                        </Box>
                        <Fab color="primary" aria-label="add" sx={fabStyle} onClick={() => navigate('/home-carousel-items')}>
                            <NavigateBeforeIcon />
                        </Fab>

                    </form>
                )
                }
            </Formik >
        </Box >
    );
}
