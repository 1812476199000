import gql from 'graphql-tag';

import { RATE_SESSION_FIELDS } from './RateSession.fragment';
//#region Queries
export const RATE_SESSION_LIST = gql`
  ${RATE_SESSION_FIELDS}
  query rateSessionList {
    rateSessionList{
      ...RateSessionFields
    }
  }
`;

export const RATE_SESSION_LIST_PENDING = gql`
  ${RATE_SESSION_FIELDS}
  query rateSessionListPending {
    rateSessionListPending{
      ...RateSessionFields
    }
  }
`;

export const RATE_SESSION_FIND_BY_ID = gql`
  ${RATE_SESSION_FIELDS}
  query rateSessionFindById($id:String!) {
    rateSessionFindById(id: $id){
      ...RateSessionFields
    }
  }
`;





//#endregion Queries

//#region Mutations
export const RATE_SESSION_UPDATE = gql`
  ${RATE_SESSION_FIELDS}
  mutation rateSessionUpdate($id: String!, $data: RateSessionInput!) {
    rateSessionUpdate(id: $id, data: $data) {
      ...RateSessionFields
    }
  }
`;


//#endregion Mutations
