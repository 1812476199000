import gql from 'graphql-tag';

export const HOME_CAROUSEL_ITEM_FIELDS = gql`
  fragment HomeCarouselItemFields on HomeCarouselItem {
    _id
    order
    name
    title
    subtitle
    button_text
    image
    subtitle_color
    is_internal
    link
    created_at
    updated_at
    images {
      original
      sm
      md
      lg
    }
  }
`;
