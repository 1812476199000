import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Alert, CircularProgress } from "@mui/material";

import { CANCELED_SESSION_LIST } from "~/Queries/CanceledSession/CanceledSession.query";
import CanceledSessionDataGrid from "~/Components/CanceledSession/CanceledSessionDataGrid";
import { useTitle } from "~/Context/TitleContext";

export default function CanceledSessionIndexPage() {
  const { loading, error, data } = useQuery(CANCELED_SESSION_LIST, {
    variables: { status: "PENDING" },
  });

  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Canceled Sessions");
  }, [setTitle]);

  if (loading) {
    return <CircularProgress />;
  }
  if (error) {
    return (
      <Alert severity="error">
        {error.message
          ? error.message
          : "Oops! An error has ocurred...Try again later!"}
      </Alert>
    );
  }

  return <CanceledSessionDataGrid data={data.canceledSessionList} />;
}
