import React from 'react';
import { DocumentNode, Operation, useApolloClient } from '@apollo/client';
import { Fab, Box, FormControl, FormHelperText, TextField, Button, Chip, Avatar, Grid, Checkbox, FormControlLabel, Select, MenuItem, Alert, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';

// third party
import * as Yup from 'yup';
import { Formik, FormikConfig, FormikProps, FormikValues, useFormik } from 'formik';


import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import { USER_ADD, USER_UPDATE } from '~/Queries/Users/User.query';
import { UserInputModel } from '~/Models/User.model';
import InputInterface from '~/Interfaces/InputInterface';
import { DefinitionNode, ObjectTypeDefinitionNode, OperationDefinitionNode, OperationTypeDefinitionNode } from 'graphql';
import { useSnackbar } from 'notistack';





const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
    zIndex: 1000
};

const Input = styled('input')({
    display: 'none',
});


class FormInputModel { };

interface FormInterface<Values> {
    id?: string | null;
    title?: string | (() => string);
    addTitle?: string | (() => string);
    editTitle?: string | (() => string);
    createMutation: DocumentNode;
    updateMutation: DocumentNode;
    children: ((props: FormikProps<Values>) => React.ReactNode);
    data?: any;
    model: typeof FormInputModel | any;
    backLink?: string;
    validationSchema?: Yup.ObjectSchema<any>;
    beforeSubmit?: (values: Object, props: FormInterface<Values>, originalValues?:any) => Object;
    hasValidData?: Boolean;
}



export default function Form<Values extends FormikValues = FormikValues, ExtraProps = {}>(props: FormInterface<Values>) {

    const navigate = useNavigate();
    const client = useApolloClient();
    const { enqueueSnackbar } = useSnackbar();







    const onSubmit = async (values: any, { setErrors, setStatus, setSubmitting }: any) => {
        try {

            let result = new props.model();
            
            result = Object.keys(result).reduce((result: any, fieldName) => {

                const value = values[fieldName];
                if (value != null) {
                    result[fieldName] = values[fieldName]
                }

                return result;
            }, result)
            if (props.beforeSubmit) {
                result = props.beforeSubmit(result, props,values)
            }
            let saved = false;
            const graphqlQuery = props.id ? props.updateMutation : props.createMutation;
            const operation = graphqlQuery.definitions.find((def) => def.kind === 'OperationDefinition') as unknown as OperationDefinitionNode;
            const operationName: string = operation && operation.name ? operation.name.value : 'ERROR';

            if (props.id) {
                //result = { file: result.file }
                const response = await client.mutate({
                    mutation: props.updateMutation,
                    variables: {
                        id: props.id,
                        data: result
                    }
                });
                saved = response.data[operationName];
            } else {

                const response = await client.mutate({
                    mutation: props.createMutation,
                    variables: {
                        data: result
                    }
                });
                saved = response.data[operationName];
            }
            if (saved) {
                await setStatus({ success: true });
                await setSubmitting(false);
                enqueueSnackbar('The item has been saved!', { variant: 'success' });
                if (props.backLink) {
                    navigate(props.backLink);
                }
            } else {
                const errorMessage = "Error submiting the item. Please try again.";
                setStatus({ success: false });
                setErrors({ submit: errorMessage });
                setSubmitting(false);
                enqueueSnackbar(errorMessage, { variant: 'error' });
            }
        }
        catch (err: any) {
            console.log({ err })
            setStatus({ success: false });
            setErrors({ submit: err.message });
            setSubmitting(false);
            enqueueSnackbar(err.message, { variant: 'error' });

        }


        setStatus({ success: false });
        setSubmitting(false);

    }



    return (
        <Box>
            <Typography variant="h1">{props.id ? props.editTitle || props.title : props.addTitle || props.title}</Typography>

            <Formik
                initialValues={props.data}
                validationSchema={props.validationSchema}
                onSubmit={onSubmit}
            >
                {(childrenProps) => (
                    <form noValidate onSubmit={childrenProps.handleSubmit}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>

                            {props.children(childrenProps)}
                            <Box sx={{ mt: 2 }}>
                                <Button
                                    disableElevation
                                    disabled={(props.hasValidData != undefined && !props.hasValidData) || childrenProps.isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Submit
                                </Button>
                            </Box>
                            <Fab color="primary" aria-label="add" sx={fabStyle} onClick={() => navigate(props.backLink ? props.backLink : '/')}>
                                <NavigateBeforeIcon />
                            </Fab>
                        </Box>
                    </form>
                )
                }
            </Formik >
        </Box >
    );
}