import React from 'react';

import { DataGrid, GridToolbar, GridActionsCellItem, GridColumns, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { ReportedIssue } from '~/Models/ReportedIssue.model';
import { IssueType } from '~/Models/Issue.model';








interface ReportedIssueDataGridProps {
    data: Array<ReportedIssue>
}

export default function CanceledSessionDataGrid({ data }: ReportedIssueDataGridProps) {

    // Navigation
    const navigate = useNavigate();


    // On Click Edit
    const onClickEdit = (params: GridRowParams) => {
        navigate(`/canceled-sessions/edit/${params.id}`);

    }
    // Columns
    const headCells: GridColumns = [
        { field: '_id', headerName: 'Id', hide: true },
        { field: 'status', headerName: 'Status' },
        {
            field: 'username', headerName: 'Username',
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.user?.name}`,
        },
        {
            field: 'fullName', headerName: 'Full name',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.user?.first_name || ''} ${params.row.user?.last_name || ''}`,
        },

        {
            field: 'canceled_by_type', headerName: 'Canceled By Type',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
                params.row.canceled_by_type
        },

        // { field: 'description', headerName: 'Description', width: 200 },
        { field: 'created_at', headerName: 'Created', width: 180 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={() => onClickEdit(params)} />
            ],
        }
    ];


    return (
        <div style={{ height: '70vh', width: '100%' }}>

            <DataGrid

                getRowId={(row) => row._id}
                rows={data}
                columns={
                    headCells
                }
                components={{
                    Toolbar: GridToolbar,
                }}
            ></DataGrid>
        </div>
    );
}


