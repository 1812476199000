import { useCallback, useRef, useState } from "react";
import { Grid, Button, Avatar, Chip, Tooltip } from "@mui/material"
import { styled } from '@mui/material/styles';
import { Image } from "~/Models/Image.model";
import { off } from "process";


const Input = styled('input')({
    display: 'none',
});


interface ImageInputProps {
    images: Image[];
    buttonText?: string;
    onChange: (file: File) => void
}

export default function ImageInput({ images, buttonText, onChange }: ImageInputProps) {
    const [previewFileData, setPreviewFileData] = useState<string>(images && images.length > 0 ? images[0].original as string : "");
    const [file, setFile] = useState<File | null>(null);
    const inputFile = useRef<HTMLInputElement>(null)

    const onButtonClick = useCallback(
        (e) => {
            e.preventDefault();
            if (inputFile && inputFile.current) {
                // `current` points to the mounted file input element
                inputFile.current.click();
            }

        }
        , []);
    const handleUpload = useCallback(
        () => (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files) {
                const reader = new FileReader();
                const file = event.target.files[0];
                reader.readAsDataURL(file); // read file as data url
                reader.onload = (fileEvent: ProgressEvent<FileReader>) => { // called once readAsDataURL is completed
                    if (fileEvent.loaded && fileEvent.target) {
                        setPreviewFileData(fileEvent.target.result as string);
                    }

                }
                setFile(file);
                onChange(file);
            }

        }, [previewFileData, file]);

    return (
        <>
            {
                previewFileData ? (
                    <>
                        <Grid item md={12} xs={12} sx={{justifyContent:'center',display:'flex',cursor: 'pointer'}}>
                            {previewFileData &&
                                <Tooltip title={file ? file?.name : "Click to change"} arrow placement="right">
                                    <Avatar src={previewFileData} variant="square" sx={{ width: 100, height: 100    }} onClick={onButtonClick} />
                                </Tooltip>
                            }
                        </Grid>

                    </>
                ) :
                    <>
                        <Grid item md={12} xs={12} sx={{justifyContent:'center',display:'flex'}}>
                            <Tooltip title={"No image"} arrow placement="right">
                                <Avatar variant="square" sx={{ width: 100, height: 100 }}>No image</Avatar>
                            </Tooltip>
                        </Grid>

                    </>
            }
            <Grid item md={12} xs={12} sx={{justifyContent:'center',display:'flex',marginTop: 2}}>
                <label htmlFor="contained-button-file">
                    <Input name="file" accept="image/*" id="contained-button-file" multiple type="file"
                        onChange={handleUpload()} ref={inputFile} />
                    <Button variant="contained" component="span" color="secondary" onClick={onButtonClick}>
                        {buttonText || 'Select image'}
                    </Button>
                </label>
            </Grid>

        </>
    )

}