
import React, { useCallback, useEffect, useState } from 'react';
import { Alert, Avatar, Button, Card, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Fab, Rating, Stack, Typography } from '@mui/material';
import TimeUtility from '~/Utilities/Time/Time.utility';
import AvatarUtility from '~/Utilities/Avatar/Avatar.utility';
import { useSnackbar } from 'notistack';
import { useApolloClient } from '@apollo/client';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useNavigate } from 'react-router-dom';
import { CanceledSession, CanceledSessionStatus } from '~/Models/CanceledSession.model';
import { IssueType } from '~/Models/Issue.model';
import { PaymentStatus } from '~/Models/Payment.model';
import { CANCELED_SESSION_UPDATE } from '~/Queries/CanceledSession/CanceledSession.query';
import { PAYMENT_REFUND } from '~/Queries/Payment/Payment.query';

const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
    zIndex: 1000
};


interface CanceledSessionFormProps {
    data: CanceledSession,
    refetch?: () => void
}

export default function CanceledSessionForm(props: CanceledSessionFormProps) {
    // Navigation
    const navigate = useNavigate();
    // Rate Session
    const [canceledSession, setCanceledSession] = useState<CanceledSession | null>(null)
    // Dialog Refund
    const [showDialogRefund, setShowDialogRefund] = useState(false);
    // Props
    const { data } = props;
    // Client
    const client = useApolloClient();
    // Snack
    const { enqueueSnackbar } = useSnackbar();
    // On Click Update Status
    const onClickUpdateStatus = async (status: CanceledSessionStatus) => {
        if (canceledSession?.status !== status) {
            try {
                const canceledSessionUpdate = {
                    status
                }
                const variables = {
                    id: canceledSession?._id,
                    data: canceledSessionUpdate
                }

                const response = await client.mutate({
                    mutation: CANCELED_SESSION_UPDATE,
                    variables: variables
                })
                const canceledSessionUpdated = response.data.canceledSessionUpdate || {};
                if (canceledSessionUpdated._id) {
                    enqueueSnackbar('The item has been saved!', { variant: 'success' });
                    setCanceledSession({ ...canceledSessionUpdated })
                } else {
                    const errorMessage = "Error submiting the item. Please try again.";
                    enqueueSnackbar(errorMessage, { variant: 'error' })
                }
            } catch (err: any) {
                enqueueSnackbar(err.message, { variant: 'error' });
            } finally {

            }
        }
    }
    // Refund
    const onClickRefund = () => {
        setShowDialogRefund(true);
    }
    const handleCloseDialogRefund = () => {
        setShowDialogRefund(false);
    }
    const onClickAcceptRefund = async () => {
        setShowDialogRefund(false);
        try {
            const variables = {
                id: canceledSession?.session.payment._id,
                reported_issue_id: canceledSession?._id
            };
            const response = await client.mutate({
                mutation: PAYMENT_REFUND,
                variables: variables
            })
            const paymentUpdate = response.data.paymentRefund || {};
            if (paymentUpdate._id) {
                enqueueSnackbar('The item has been saved!', { variant: 'success' });
                if (props.refetch) {
                    props.refetch();
                }
            } else {
                const errorMessage = "Error submiting the item. Please try again.";
                enqueueSnackbar(errorMessage, { variant: 'error' })
            }

        } catch (err: any) {
            enqueueSnackbar(err.message, { variant: 'error' });
        } finally {

        }

    }
    const fetchData = useCallback(() => {
        setCanceledSession({ ...data })
    }, [data])
    useEffect(() => {
        fetchData()
    }, [data, fetchData])


    if (!canceledSession) {
        return null;
    }

    const renderSessionInformation = () => {
        console.log({ canceledSession })
        const session = canceledSession;
        const gamer = canceledSession.gamer;
        const images = gamer.images;
        const image = images && images.length > 0 ? images[0].original as string : "";
        return (
            <>
                <Dialog
                    open={showDialogRefund}
                    maxWidth={'xs'}
                    fullWidth={true}
                    onClose={handleCloseDialogRefund}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" color={'primary'} sx={{ fontSize: '1.25rem' }}>
                        {"Are you sure to refund?"}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText variant="h4" color={'textDark'}>
                            {`This action cant't be undone`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseDialogRefund} variant="text">Cancel</Button>
                        <Button onClick={onClickAcceptRefund} autoFocus variant="contained">
                            Accept
                        </Button>
                    </DialogActions>
                </Dialog>
                <Stack mt={2} mb={2}>
                    <Divider variant="middle" />
                    <Typography variant="h4" mt={2}>
                        Session Information
                    </Typography>
                </Stack>
                <Stack >
                    <Typography variant="h5">
                        Gamer:
                    </Typography>
                </Stack>
                <CardHeader
                    avatar={
                        <Avatar sx={{ bgcolor: 'primary.main', color: '#fff' }} src={image} >
                            {`${canceledSession.canceled_by_user.first_name.charAt(0)}${canceledSession.canceled_by_user.last_name.charAt(0)}`}
                        </Avatar>
                    }
                    title={`${canceledSession.canceled_by_user.first_name} ${canceledSession.canceled_by_user.last_name}`}
                    subheader={<Typography>{`Owrlee user since ${TimeUtility.format(canceledSession.canceled_by_user.created_at, 'MMMM YYYY')}`}</Typography>}
                />
                <Stack mt={2}>
                    <Typography variant="h5">
                        Type:
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {session.session_type}
                    </Typography>
                </Stack>
                <Stack mt={2}>
                    <Typography variant="h5">
                        Status:
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {session.payment.status}
                    </Typography>
                </Stack>

                <Stack mt={2}>
                    <Typography variant="h5">
                        Amount:
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {`$${session.payment.decimal_amount}`}
                    </Typography>
                    {session.payment.status !== PaymentStatus.REFUND &&
                        <Stack spacing={2} mt={0} direction="row">
                            <Button variant={'contained'} onClick={onClickRefund}>Refund</Button>
                        </Stack>}
                    {session.payment.status === PaymentStatus.REFUND &&
                        <Stack spacing={2} mt={0} direction="row">
                            <Alert severity="info">{`This payment has been refunded. Remember: A manual action is required from Stripe.`}</Alert>
                        </Stack>}

                </Stack>
                <Stack mt={2}>
                    <Typography variant="h5">
                        Start Date:
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {TimeUtility.format(session.start_date, "YYYY-MM-DD hh:mmA")}
                    </Typography>

                </Stack>
                <Stack mt={2}>
                    <Typography variant="h5">
                        End Date:
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {TimeUtility.format(session.end_date, "YYYY-MM-DD hh:mmA")}
                    </Typography>
                </Stack>
            </>
        )
    }
    // Avatar
    const avatar = AvatarUtility.getAvatar(canceledSession.user.avatar_sku)
    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: 'primary.main', color: '#fff' }} src={avatar ? avatar.image : ''} >
                        {`${canceledSession.user.first_name.charAt(0)}${canceledSession.user.last_name.charAt(0)}`}
                    </Avatar>
                }
                title={`${canceledSession.user.first_name} ${data.user.last_name}`}
                subheader={<Typography>{`Owrlee user since ${TimeUtility.format(canceledSession.user.created_at, 'MMMM YYYY')}`}</Typography>}
            />

            <CardContent>
                {/* Status */}
                <Stack >
                    <Typography variant="h5" >
                        Status:
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {`${canceledSession.status} `}
                    </Typography>
                </Stack>

                {/* Description */}
                <Stack mt={2}>
                    <Typography variant="h5" >
                        Canceled By:
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {canceledSession.canceled_by_user.first_name} {canceledSession.canceled_by_user.last_name}
                    </Typography>
                </Stack>
                <Stack mt={2}>
                    <Typography variant="h5" >
                        Canceled By type:
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {canceledSession.canceled_by_type}
                    </Typography>
                </Stack>
                <Stack mt={2}>
                    <Typography variant="h5" >
                        Created:
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {`${canceledSession.created_at} `}
                    </Typography>
                </Stack>



                <Stack spacing={2} mt={4} direction="row">
                    <Button onClick={() => onClickUpdateStatus(CanceledSessionStatus.PROCESSING)} variant={canceledSession.status === CanceledSessionStatus.PROCESSING ? 'contained' : 'outlined'}>Processing</Button>
                    <Button onClick={() => onClickUpdateStatus(CanceledSessionStatus.RESOLVED)} variant={canceledSession.status === CanceledSessionStatus.RESOLVED ? 'contained' : 'outlined'}>Resolved</Button>
                </Stack>

                <Fab color="primary" aria-label="add" sx={fabStyle} onClick={() => navigate(-1)}>
                    <NavigateBeforeIcon />
                </Fab>
            </CardContent>

        </Card>
    )
}