import { useQuery } from "@apollo/client";
import { Alert, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import RateSessionForm from "~/Components/RateSession/RateSessionForm";
import { useTitle } from "~/Context/TitleContext";
import { RateSession } from "~/Models/RateSession.model";
import { RATE_SESSION_FIND_BY_ID } from "~/Queries/RateSession/RateSession.query";

const RateSessionEditPage = () => {
  const { id } = useParams();

  const { data, loading, error } = useQuery(RATE_SESSION_FIND_BY_ID, {
    variables: { id: id },
  });

  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Rate Sessions / Edit");
  }, [setTitle]);

  if (error) {
    return (
      <Alert severity="error">
        {error.message
          ? error.message
          : "Oops! An error has ocurred...Try again later!"}
      </Alert>
    );
  }
  return loading ? (
    <CircularProgress />
  ) : (
    <RateSessionForm data={data.rateSessionFindById as RateSession} />
  );
};

export default RateSessionEditPage;
