import { useQuery } from "@apollo/client";
import { CircularProgress, Avatar, Fab } from "@mui/material";
import { useNavigate } from "react-router-dom";
import {
  DataGrid,
  GridToolbar,
  GridActionsCellItem,
  GridColumns,
} from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";

import { GAME_DELETE, GAME_LIST } from "~/Queries/Games/Game.query";
import ListPage from "~/Components/ListPage";
import { useEffect } from "react";
import { useTitle } from "~/Context/TitleContext";

const fabStyle = {
  position: "absolute",
  bottom: 16,
  right: 16,
  zIndex: 1000,
};

const headCells: GridColumns = [
  { field: "_id", headerName: "Id", hide: true },
  {
    field: "image",
    headerName: "Image",
    renderCell: (params) => {
      if (!params.row.images) {
        return <Avatar alt="Image">NI</Avatar>;
      }
      return <Avatar src={params.row.images[0].sm} alt="Image" />;
    },
  },
  { field: "name", headerName: "Name" },

  { field: "created_at", headerName: "Created", width: 180 },
];

export default function GamesPage() {
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Games");
  }, [setTitle]);
  return (
    <ListPage
      query={GAME_LIST}
      headCells={headCells}
      deleteQuery={GAME_DELETE}
      addActionUrl={"/games/add"}
      editActionUrl={"/games/edit"}
    />
  );
}
