import * as React from "react";
import { NavLink, NavLinkProps } from "react-router-dom";

import List from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import LogoutIcon from "@mui/icons-material/Logout";
import BugReportIcon from "@mui/icons-material/BugReport";
import SlideshowIcon from "@mui/icons-material/Slideshow";
import StarRateIcon from "@mui/icons-material/StarRate";
import LayersIcon from "@mui/icons-material/Layers";
import RecordVoiceOverIcon from "@mui/icons-material/RecordVoiceOver";

import PayoutRequestIcon from "@mui/icons-material/PaymentRounded";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

import ChevronRightIcon from "@mui/icons-material/ChevronRight";

type RouterLinkProps = React.PropsWithChildren<{
  to: string;
  text: string;
  icon: React.ReactElement;
  padding?: boolean;
  end?: boolean;
}>;
const RouterLink = (props: RouterLinkProps) => {
  type MyNavLinkProps = Omit<NavLinkProps, "to">;
  const MyNavLink = React.useMemo(
    () =>
      React.forwardRef<HTMLAnchorElement, MyNavLinkProps>(
        (navLinkProps, ref) => {
          const { className: previousClasses, ...rest } = navLinkProps;
          const elementClasses = previousClasses?.toString() ?? "";
          return (
            <NavLink
              {...rest}
              ref={ref}
              to={props.to}
              end={props.end}
              className={({ isActive }) =>
                isActive ? elementClasses + " Mui-selected" : elementClasses
              }
            />
          );
        }
      ),
    [props.to]
  );
  return (
    <ListItemButton component={MyNavLink} sx={props.padding ? { pl: 4 } : {}}>
      <ListItemIcon
        sx={{
          ".Mui-selected > &": {
            color: (theme) => theme.palette.primary.light,
          },
        }}
      >
        {props.icon}
      </ListItemIcon>
      <ListItemText
        primary={props.text}
        sx={{
          ".Mui-selected > & .MuiTypography-root": {
            color: (theme) => theme.palette.primary.light,
          },
        }}
      />
    </ListItemButton>
  );
};

interface CollapsedLinkProps {
  text: string;
  children: React.ReactElement[] | React.ReactElement;
  icon: React.ReactElement;
}
const CollapsedLink = (props: CollapsedLinkProps) => {
  const [open, setOpen] = React.useState(true);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemIcon
          sx={{
            ".Mui-selected > &": {
              color: (theme) => theme.palette.primary.light,
            },
          }}
        >
          {props.icon}
        </ListItemIcon>
        <ListItemText
          primary={props.text}
          sx={{
            ".Mui-selected > & .MuiTypography-root": {
              color: (theme) => theme.palette.primary.light,
            },
          }}
        />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {props.children}
        </List>
      </Collapse>
    </>
  );
};

export const SideBarItems = (
  <React.Fragment>
    <RouterLink to="/" text="Dashboard" icon={<DashboardIcon />} />
    <RouterLink
      to="/home-carousel-items"
      text="Home Carousel"
      icon={<SlideshowIcon />}
    />
    {/* User */}
    <CollapsedLink icon={<PeopleIcon />} text="Users">
      <RouterLink
        to="/users"
        text="List"
        icon={<ChevronRightIcon />}
        padding
        end
      />
      <RouterLink
        to="/users/inactive"
        text="Inactive"
        icon={<ChevronRightIcon />}
        padding
        end
      />
    </CollapsedLink>
    {/* Gamers */}
    <CollapsedLink icon={<PeopleIcon />} text="Gamers">
      <RouterLink
        to="/gamers"
        text="List"
        icon={<ChevronRightIcon />}
        padding
        end
      />
      <RouterLink
        to="/gamers/inactive"
        text="Inactive"
        icon={<ChevronRightIcon />}
        padding
        end
      />
      <RouterLink
        to="/gamers/pricing"
        text="Pricing"
        icon={<ChevronRightIcon />}
        padding
        end
      />
    </CollapsedLink>
    {/* Admin */}
    <CollapsedLink icon={<PeopleIcon />} text="Admin">
      <RouterLink
        to="/admin"
        text="List"
        icon={<ChevronRightIcon />}
        padding
        end
      />
      <RouterLink
        to="/admin/inactive"
        text="Inactive"
        icon={<ChevronRightIcon />}
        padding
        end
      />
    </CollapsedLink>
    {/* Games */}
    <RouterLink to="/games" text="Games" icon={<PeopleIcon />} />
    <RouterLink to="/platforms" text="Platforms" icon={<LayersIcon />} />
    <CollapsedLink icon={<PayoutRequestIcon />} text="Payouts">
      <RouterLink
        to="/payout-requests"
        text="List"
        icon={<ChevronRightIcon />}
        padding
        end
      />
      <RouterLink
        to="/payout-requests/pending"
        text="Pending"
        icon={<ChevronRightIcon />}
        padding
        end
      />
    </CollapsedLink>
    {/* Reported Issues */}
    <CollapsedLink icon={<BugReportIcon />} text="Reported Issues">
      <RouterLink
        to="/reported-issues"
        text="List"
        icon={<ChevronRightIcon />}
        padding
        end
      />
      <RouterLink
        to="/reported-issues/pending"
        text="Pending"
        icon={<ChevronRightIcon />}
        padding
        end
      />
    </CollapsedLink>

    <CollapsedLink icon={<BugReportIcon />} text="Canceled Sessions">
      <RouterLink
        to="/canceled-sessions"
        text="List"
        icon={<ChevronRightIcon />}
        padding
        end
      />
      {
        //<RouterLink to="/canceled-sessions/pending" text="Pending" icon={<ChevronRightIcon />} padding end />
      }
    </CollapsedLink>
    {/* Rate Session */}
    <CollapsedLink icon={<StarRateIcon />} text="Rate Sessions">
      <RouterLink
        to="/rate-sessions"
        text="List"
        icon={<ChevronRightIcon />}
        padding
        end
      />
      <RouterLink
        to="/rate-sessions/pending"
        text="Pending"
        icon={<ChevronRightIcon />}
        padding
        end
      />
    </CollapsedLink>
    {/* Suggestions */}
    <RouterLink
      to="/suggestions"
      text="Suggestions"
      icon={<RecordVoiceOverIcon />}
    />
    {/* Logout */}
    <RouterLink to="/logout" text="Logout" icon={<LogoutIcon />} />
  </React.Fragment>
);
