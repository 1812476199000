import Moment, { unitOfTime } from 'moment';

import { SlotTime } from '~/Models/SlotTime.model';

export default class TimeUtility {
  static format(date?: Date | string, format = 'YYY/MM/DD'): string {
    return Moment(date).format(format);
  }
  static getEndOF(
    date?: Date | string,
    endOf: unitOfTime.StartOf = 'month',
    format = 'YYY/MM/DD'
  ): string {
    return Moment(date).endOf(endOf).format(format);
  }
  static getTimeStops(start: string, end: string): string[] {
    const startTime = Moment(start, 'HH:mm');
    const endTime = Moment(end, 'HH:mm');

    if (endTime.isBefore(startTime)) {
      endTime.add(1, 'day');
    }

    const timeStops = [];

    while (startTime <= endTime) {
      timeStops.push(Moment(startTime).format('HH:mma'));
      startTime.add(75, 'minutes');
    }
    return timeStops;
  }
  static slotToDate(slot: SlotTime): Date {
    const toParse = `${slot.date} ${parseInt(slot.hour, 10) <= 9 ? `0${slot.hour}` : slot.hour}:00`;
    return Moment(toParse).toDate();
  }
  static differenceInHours(dateA: Date, dateB: Date): number {
    const a = Moment(dateA);
    const b = Moment(dateB);
    return a.diff(b, 'hours');
  }
  static differenceInSeconds(dateA: Date, dateB: Date): number {
    const a = Moment(dateA);
    const b = Moment(dateB);
    return a.diff(b, 'seconds');
  }

  static isAfterToday(purchaseDate: string | Date): boolean {
    // 2021-10-13

    return Moment(purchaseDate).isAfter(Moment(), 'day');
  }
  static isSameOrAfterToday(purchaseDate: string | Date): boolean {
    // 2021-10-13
    return Moment(purchaseDate).isSameOrAfter(Moment(), 'day');
  }
  static getDaysArrayByMonth(date: string | Date, format = 'YYY/MM/DD'): string[] {
    console.log('date', date);
    let daysInMonth = Moment(date).daysInMonth();
    const arrDays = [];

    while (daysInMonth) {
      const current = Moment(date).date(daysInMonth).format(format);
      arrDays.push(current);
      daysInMonth--;
    }
    return arrDays;
  }
}
