
import React, { useCallback, useEffect, useState } from 'react';



import { RateSession, RateSessionStatus } from "~/Models/RateSession.model";

import { Avatar, Button, Card, CardContent, CardHeader, Fab, Rating, Stack, Typography } from '@mui/material';
import TimeUtility from '~/Utilities/Time/Time.utility';
import AvatarUtility from '~/Utilities/Avatar/Avatar.utility';
import { useSnackbar } from 'notistack';
import { useApolloClient } from '@apollo/client';
import { RATE_SESSION_UPDATE } from '~/Queries/RateSession/RateSession.query';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useNavigate } from 'react-router-dom';

const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
    zIndex: 1000
};


interface RateSessionFormInterface {
    data: RateSession,
}

export default function RateSessionForm(props: RateSessionFormInterface) {
    // Navigation
    const navigate = useNavigate();
    // Rate Session
    const [rateSession, setRateSession] = useState<RateSession | null>(null)
    // Props
    const { data } = props;
    // Client
    const client = useApolloClient();
    // Snack
    const { enqueueSnackbar } = useSnackbar();
    // On Click Update Status
    const onClickUpdateStatus = async (status: RateSessionStatus) => {
        if (rateSession?.status !== status) {
            const rateSessionUpdate = {
                user_id: rateSession?.user_id,
                gamer_id: rateSession?.gamer_id,
                session_id: rateSession?.session_id,
                rate: rateSession?.rate,
                status
            }
            const variables = {
                id: rateSession?._id,
                data: rateSessionUpdate
            }

            const response = await client.mutate({
                mutation: RATE_SESSION_UPDATE,
                variables: variables
            })
            const rateSessionUpdated = response.data.rateSessionUpdate || {};
            if (rateSessionUpdated._id) {
                enqueueSnackbar('The item has been saved!', { variant: 'success' });
                setRateSession({ ...rateSessionUpdated })
            } else {
                const errorMessage = "Error submiting the item. Please try again.";
                enqueueSnackbar(errorMessage, { variant: 'error' })
            }
        }
    }
    const fetchData = useCallback(() => {
        setRateSession({ ...data })
    }, [data])
    useEffect(() => {
        fetchData()
    }, [data, fetchData])

    if (!rateSession) {
        return null;
    }
    // Avatar
    const avatar = AvatarUtility.getAvatar(rateSession.user.avatar_sku)
    return (
        <Card sx={{ maxWidth: 345 }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: 'primary.main', color: '#fff' }} src={avatar ? avatar.image : ''} >
                        {`${rateSession.user.first_name.charAt(0)}${rateSession.user.last_name.charAt(0)}`}
                    </Avatar>
                }
                title={`${rateSession.user.first_name} ${rateSession.user.last_name}`}
                subheader={<Typography>{`Owrlee user since ${TimeUtility.format(rateSession.user.created_at, 'MMMM YYYY')}`}</Typography>}
            />

            <CardContent>
                <Stack >
                    <Typography variant="h5" >
                        Rate session of:
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {`${data.gamer.first_name} ${data.gamer.last_name} `}
                    </Typography>
                </Stack>
                {/* Mesage */}
                <Stack mt={2}>
                    <Typography variant="h5" >
                        Message
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {`${data.message}`}
                    </Typography>
                </Stack>
                {/* Tip */}
                {data.tip &&
                    <Stack mt={2}>
                        <Typography variant="h5" >
                            Tip
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {`${rateSession.tip}`}
                        </Typography>
                    </Stack>
                }
                {/* Rating */}
                <Stack mt={2}>
                    <Typography variant="h5" >
                        Rating
                    </Typography>
                    <Rating name="read-only" value={rateSession.rate} readOnly />
                </Stack>

                <Stack spacing={2} mt={4} direction="row">
                    <Button onClick={() => onClickUpdateStatus(RateSessionStatus.APPROVED)} variant={rateSession.status === RateSessionStatus.APPROVED ? 'contained' : 'outlined'}>Approve</Button>
                    <Button onClick={() => onClickUpdateStatus(RateSessionStatus.DISAPPROVED)} variant={rateSession.status === RateSessionStatus.DISAPPROVED ? 'contained' : 'outlined'}>Disapprove</Button>
                </Stack>

                <Fab color="primary" aria-label="add" sx={fabStyle} onClick={() => navigate(-1)}>
                    <NavigateBeforeIcon />
                </Fab>
            </CardContent>

        </Card>
    )
}