import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, TextField, FormHelperText } from '@mui/material';

import * as Yup from "yup";
import { Formik } from 'formik';


interface DialogInvalidForm {
    message: string;
}


interface DialogPayoutRequestInvalidProps {
    show: boolean,
    handleOnClose: () => void
    handleOnAccept: (message: string) => void
}
export default function DialogPayoutRequestInvalid({ show, handleOnClose, handleOnAccept }: DialogPayoutRequestInvalidProps) {




    const validationSchema = Yup.object().shape({
        message: Yup.string().max(255).required("You must enter a reason."),
    });
    const onSubmit = (values: DialogInvalidForm) => {
        handleOnAccept(values.message)
    }



    return (
        <Formik
            initialValues={{ message: '' }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {({
                errors,
                handleBlur,
                setFieldValue,
                handleChange,
                handleSubmit,
                isSubmitting,
                touched,
                values,
            }) => (
                <form noValidate onSubmit={handleSubmit}>
                    <Dialog
                        open={show}
                        maxWidth={'xs'}
                        fullWidth={true}
                        onClose={handleOnClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title" color={'primary'} sx={{ fontSize: '1.25rem' }}>
                            {/* <Typography variant="h3" color={'primary'}> */}
                            {"Are you sure to invalid payout request?"}
                            {/* </Typography> */}
                        </DialogTitle>
                        <DialogContent>
                            <TextField
                                label="Reason"
                                name="message"
                                value={values.message}
                                fullWidth
                                onBlur={handleBlur}
                                onChange={handleChange}
                            />
                            {touched.message && errors.message && (
                                <FormHelperText error >
                                    {errors.message}
                                </FormHelperText>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleOnClose} variant="text">Cancel</Button>
                            <Button onClick={() => handleSubmit()} autoFocus variant="contained">
                                Accept
                            </Button>
                        </DialogActions>
                    </Dialog>
                </form>
            )}
        </Formik>
    )
}