import gql from 'graphql-tag';

export const RATE_SESSION_FIELDS = gql`
  fragment RateSessionFields on RateSession {
    _id
    session_id
    gamer_id
    user_id
    #0-5
    rate
    #0-100
    tip
    active
    message
    created_at
    updated_at
    status
    user {
        avatar_sku
        name
        email
        first_name
        last_name
        avatar_sku
        created_at
    }
    gamer {
        name
        email
        first_name
        last_name
        created_at
    }

  }
`;
