import gql from "graphql-tag";

//#region Queries

export const PAYMENT_HISTORY = gql`
  query paymentHistory {
    paymentHistory {
      _id
      payment_type
      amount
      to_user_amount
      to_app_amount
      payment_date
      description
      created_at
      updated_at
      canceled_session {
        canceled_by_type
        session_type
        start_date
        game {
          name
        }
        gamer {
          name
          display_name
          first_name
          last_name
          email
          sex
          profit_percent
          #pricing
          price_off_stream
          price_on_stream
          profit_percent_off_stream
          profit_percent_on_stream
        }
      }
      session {
        session_type
        start_date
        game {
          name
        }
        gamer {
          name
          display_name
          first_name
          last_name
          email
          sex
          profit_percent
          #pricing
          price_off_stream
          price_on_stream
          profit_percent_off_stream
          profit_percent_on_stream
        }
      }
      user {
        name
        first_name
        last_name
        email
        sex
      }
    }
  }
`;

export const PAYMENT_UPDATE = gql`
  mutation paymentUpdate($id: String!, $data: PaymentInput!) {
    paymentUpdate(id: $id, data: $data) {
      _id
    }
  }
`;

export const PAYMENT_REFUND = gql`
  mutation paymentRefund($id: String!, $reported_issue_id: String!) {
    paymentRefund(id: $id, reported_issue_id: $reported_issue_id) {
      _id
    }
  }
`;

//#endregion Queries

//#region Mutations

//#endregion Mutations
