
import React, { useCallback, useEffect, useState } from 'react';

import { Avatar, Button, Card, CardContent, CardHeader, Fab, Grid, Stack, Typography } from '@mui/material';
import TimeUtility from '~/Utilities/Time/Time.utility';
import { useSnackbar } from 'notistack';
import { useApolloClient } from '@apollo/client';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { useNavigate } from 'react-router-dom';
import { PayoutRequest, PayoutRequestStatus } from '~/Models/PayoutRequest.model';
import { PAYOUT_REQUEST_ADMIN_UPDATE } from '~/Queries/PayoutRequest/PayoutRequest.query';
import DialogPayoutRequestInvalid from './DialogPayoutRequestInvalid';

const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
    zIndex: 1000
};


interface PayoutRequestFormProps {
    data: PayoutRequest
}

export default function PayoutRequestForm(props: PayoutRequestFormProps) {
    // Navigation
    const navigate = useNavigate();
    // Rate Session
    const [payoutRequest, setPayoutRequest] = useState<PayoutRequest | null>(null)
    // Dialog
    const [showDialogInvalid, setShowDialogInvalid] = useState(false);
    // Props
    const { data } = props;
    // Client
    const client = useApolloClient();
    // Snack
    const { enqueueSnackbar } = useSnackbar();
    // On Click Update Status
    const onClickUpdateStatus = async (status: PayoutRequestStatus, message = '') => {
        if (payoutRequest?.status !== status || status === PayoutRequestStatus.INVALID) {
            const payoutRequestUpdate = {
                status,
                reason: message
            }
            const variables = {
                id: payoutRequest?._id,
                data: payoutRequestUpdate
            }

            const response = await client.mutate({
                mutation: PAYOUT_REQUEST_ADMIN_UPDATE,
                variables: variables
            })
            const payoutRequestUpdated = response.data.payoutRequestAdminUpdate || {};
            if (payoutRequestUpdated._id) {
                enqueueSnackbar('The item has been saved!', { variant: 'success' });
                setPayoutRequest({ ...payoutRequestUpdated })
            } else {
                const errorMessage = "Error submiting the item. Please try again.";
                enqueueSnackbar(errorMessage, { variant: 'error' })
            }
        }
    }
    // Dialog Invalid Flow
    const onClickUpdateStatusInvalid = () => {
        setShowDialogInvalid(true);
    }
    const handleCloseDialogInvalid = () => {
        setShowDialogInvalid(false);
    }
    const handleOnAcceptDialogInvalid = (message: string) => {
        setShowDialogInvalid(false);
        onClickUpdateStatus(PayoutRequestStatus.INVALID, message);
    }


    const fetchData = useCallback(() => {
        setPayoutRequest({ ...data })
    }, [data])
    useEffect(() => {
        fetchData()
    }, [data, fetchData])

    if (!payoutRequest) {
        return null;
    }
    // Avatar
    const images = payoutRequest.user.images;
    const image = images && images.length > 0 ? images[0].original as string : "";
    return (
        <Card sx={{ maxWidth: 800 }}>
            <CardHeader
                avatar={
                    <Avatar sx={{ bgcolor: 'primary.main', color: '#fff' }} src={image} >
                        {`${payoutRequest.user.first_name.charAt(0)}${payoutRequest.user.last_name.charAt(0)}`}
                    </Avatar>
                }
                title={`${payoutRequest.user.first_name} ${payoutRequest.user.last_name}`}
                subheader={<Typography>{`Owrlee user since ${TimeUtility.format(payoutRequest.user.created_at, 'MMMM YYYY')}`}</Typography>}
            />

            <CardContent>
                <Grid container spacing={2}>
                    <Grid item md={6} xs={12}>
                        <Stack >
                            <Typography variant="h5" >
                                ID:
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {`${payoutRequest._id} `}
                            </Typography>
                        </Stack>
                        <Stack mt={2}>
                            <Typography variant="h5" >
                                Status:
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {`${payoutRequest.status} `}
                            </Typography>
                        </Stack>
                        {/* Ammount */}
                        <Stack mt={2}>
                            <Typography variant="h5" >
                                Ammount:
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {`$${payoutRequest.decimal_amount}`}
                            </Typography>
                        </Stack>
                        <Stack mt={2}>
                            <Typography variant="h5" >
                                Requested:
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                {`${payoutRequest.created_at} `}
                            </Typography>
                        </Stack>
                        {payoutRequest.reason &&
                            <Stack mt={2}>
                                <Typography variant="h5" >
                                    Reason:
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {`${payoutRequest.reason} `}
                                </Typography>
                            </Stack>}
                    </Grid>
                    <Grid item md={6} xs={12} border={'solid'} pb={2}>
                        <Stack>
                            <Typography variant="h3" >
                                Bank Account information of gamer:
                            </Typography>
                            {/* Account Holder Name */}
                            <Stack mt={2}>
                                <Typography variant="h5" >
                                    Account Holder Name:
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {`${payoutRequest.user.account_holder_name ? payoutRequest.user.account_holder_name : 'n/a'}`}
                                </Typography>
                            </Stack>
                            {/* Account holder type */}
                            <Stack mt={2}>
                                <Typography variant="h5" >
                                    Account holder type:
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {`${payoutRequest.user.account_holder_type ? payoutRequest.user.account_holder_type : 'n/a'}`}
                                </Typography>
                            </Stack>
                            {/* Account type: */}
                            <Stack mt={2}>
                                <Typography variant="h5" >
                                    Account type:
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {`${payoutRequest.user.account_type ? payoutRequest.user.account_type : 'n/a'}`}
                                </Typography>
                            </Stack>
                            {/* Bank Name */}
                            <Stack mt={2}>
                                <Typography variant="h5" >
                                    Bank Name:
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {`${payoutRequest.user.bank_name ? payoutRequest.user.bank_name : 'n/a'}`}
                                </Typography>
                            </Stack>
                            {/* Account Routing number */}
                            <Stack mt={2}>
                                <Typography variant="h5" >
                                    Account Routing number:
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {`${payoutRequest.user.account_routing_number ? payoutRequest.user.account_routing_number : 'n/a'}`}
                                </Typography>
                            </Stack>
                            {/* Account number */}
                            <Stack mt={2}>
                                <Typography variant="h5" >
                                    Account number:
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    {`${payoutRequest.user.account_number ? payoutRequest.user.account_number : 'n/a'}`}
                                </Typography>
                            </Stack>
                        </Stack>

                    </Grid>
                </Grid>
                <Stack spacing={2} mt={4} direction="row">
                    <Button onClick={() => onClickUpdateStatus(PayoutRequestStatus.PROCESSING)} variant={payoutRequest.status === PayoutRequestStatus.PROCESSING ? 'contained' : 'outlined'}>Processing</Button>
                    <Button onClick={() => onClickUpdateStatus(PayoutRequestStatus.CONFIRMED)} variant={payoutRequest.status === PayoutRequestStatus.CONFIRMED ? 'contained' : 'outlined'}>Confirmed</Button>
                    <Button onClick={() => onClickUpdateStatusInvalid()} variant={payoutRequest.status === PayoutRequestStatus.INVALID ? 'contained' : 'outlined'}>Invalid</Button>
                </Stack>

                <Fab color="primary" aria-label="add" sx={fabStyle} onClick={() => navigate(-1)}>
                    <NavigateBeforeIcon />
                </Fab>
            </CardContent>
            {/* Dialog */}
            <DialogPayoutRequestInvalid show={showDialogInvalid} handleOnClose={handleCloseDialogInvalid} handleOnAccept={handleOnAcceptDialogInvalid} />

        </Card>
    )
}