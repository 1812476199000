import gql from "graphql-tag";

//#region Queries

export const ADMIN_DASHBOARD = gql`
  query adminDashboard{
    adminDashboard {
      gamers_in_app
      users_in_app
      today_user_reservations_average
      future_user_reservations_average
      today_total_reservations
      future_total_reservations
      total_sessions_completed
      revenue_average
      revenue_12_months
      revenue_6_months
      revenue_3_months
      canceled_user_sessions
      canceled_gamer_sessions
      on_stream_purchases
      off_stream_purchases
      most_purchased_game {
        name
      }
      most_purchased_gamer {
        display_name
      }
    }
  }
`;
