import gql from 'graphql-tag';

import { GAMER_FIELDS } from './Gamer.fragment';
//#region Queries

export const GAMER_TOP_LIST = gql`
  ${GAMER_FIELDS}
  query gamerTopList($cant: Float!) {
    gamerTopList(cant: $cant) {
      ...GamerFields
    }
  }
`;

export const GAMER_FIND_BY_ID = gql`
  ${GAMER_FIELDS}
  query userFindById($user_id: String!) {
    userFindById(user_id: $user_id) {
      ...GamerFields
      games {
        _id
        game {
          _id
          name
          images {
            original
            sm
            md
            lg
          }
        }
        platform {
          _id
          name
          description
        }
      }
    }
  }
`;
export const GAMER_LIST = gql`
  ${GAMER_FIELDS}
  query gamerList {
    gamerList{
      ...GamerFields
    }
  }
`;

export const GAMER_FIND_BY_GAME_AND_PLATFORM = gql`
  ${GAMER_FIELDS}
  query gamerFindByGameAndPlarform($game_id: String!, $platform_id: String!) {
    gamerFindByGameAndPlarform(game_id: $game_id, platform_id: $platform_id) {
      ...GamerFields
      # we need when call gamer search to improve
      games {
        _id
        game {
          _id
          name
          images {
            original
            sm
            md
            lg
          }
        }
        platform {
          _id
          name
          description
        }
      }
    }
  }
`;


export const GAMER_FIND_BY_TYPE = gql`
  ${GAMER_FIELDS}
  query userFindByType {
    userFindByType(type: "gamer",filter:  { active: true }) {
      ...GamerFields
    }
  }
`;

//#endregion Queries

//#region Mutations

export const GAMER_UPDATE_PRICING = gql`
  ${GAMER_FIELDS}
  mutation gamerUpdatePricing($id: String!, $data: GamerPricingInput!) {
    gamerUpdatePricing(id: $id, data: $data) {
      ...GamerFields
    }
  }
`;


//#endregion Mutations
