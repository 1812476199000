/* eslint-disable import/no-anonymous-default-export */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { useRecoilValue } from "recoil";

import { authUserAtom } from "./Recoil/Auth.recoil";

import AdminLayout from "./Layout/Admin";
import LoginLayout from "./Layout/Login";

import HomePage from "./Pages/HomePage";
import UsersPage from "./Pages/UsersPage";
import LoginPage from "./Pages/LoginPage";
import ReportedIssuesIndexPage from "./Pages/ReportedIssue/ReportedIssuesIndexPage";
import GamesPage from "./Pages/GamesPage";
import HomeCarouselItemPage from "./Pages/HomeCarouselItem/HomeCarouselItemsPage";
import HomeCarouselItemAddPage from "./Pages/HomeCarouselItem/HomeCarouselItemAddPage";
import HomeCarouselItemEditPage from "./Pages/HomeCarouselItem/HomeCarouselItemEditPage";
import UserAddPage from "./Pages/User/UserAddPage";
import UserEditPage from "./Pages/User/UserEditPage";
import GameAddPage from "./Pages/Game/GameAddPage";
import PlatformPage from "./Pages/PlatformPage";
import PlatformAddPage from "./Pages/Platform/PlatformAddPage";
import GameEditPage from "./Pages/Game/GameEditPage";
import PlatformEditPage from "./Pages/Platform/PlatformEditPage";
import RateSessionsPage from "./Pages/RateSessionsPage";
import RateSessionPendingPage from "./Pages/RateSessions/RateSessionPendingPage";
import RateSessionEditPage from "./Pages/RateSessions/RateSessionEditPage";
import LogoutPage from "./Pages/LogoutPage";
import PayoutRequestIndexPage from "./Pages/PayoutRequest/PayoutRequestIndexPage";
import PayoutRequestPendingPage from "./Pages/PayoutRequest/PayoutRequestPendingPage";
import PayoutRequestEditPage from "./Pages/PayoutRequest/PayoutRequestEditPage";
import ReportedIssuesPendingPage from "./Pages/ReportedIssue/ReportedIssuesPendingPage";
import ReportedIssueEditPage from "./Pages/ReportedIssue/ReportedIssueEditPage";
import SuggestionIndexPage from "./Pages/Suggestion/SuggestionIndexPage";
import SuggestionEditPage from "./Pages/Suggestion/SuggestionEditPage";
import CanceledSessionIndexPage from "./Pages/CanceledSession/CanceledSessionIndexPage";
import CanceledSessionEditPage from "./Pages/CanceledSession/CanceledSessionEditPage";
import GamerPricingPage from "./Pages/Gamer/GamerPricingPage";
import TitleProvider from "./Context/TitleContext";

export default () => {
  const authUser = useRecoilValue(authUserAtom);
  return (
    <Routes>
      {!authUser && (
        <Route
          element={
            <LoginLayout>
              <Outlet />
            </LoginLayout>
          }
        >
          <Route path="/login" element={<LoginPage />} />
        </Route>
      )}
      {authUser && (
        <Route
          element={
            <TitleProvider>
              <AdminLayout>
                <Outlet />
              </AdminLayout>
            </TitleProvider>
          }
        >
          <Route path="/" element={<HomePage />} />
          <Route path="/logout" element={<LogoutPage />} />
          <Route path="/home-carousel-items">
            <Route
              path="/home-carousel-items"
              element={<HomeCarouselItemPage />}
            />
            <Route
              path="/home-carousel-items/add"
              element={<HomeCarouselItemAddPage />}
            />
            <Route
              path="/home-carousel-items/edit/:id"
              element={<HomeCarouselItemEditPage />}
            />
          </Route>
          <Route path="/users">
            <Route path="/users" element={<UsersPage type="user" />} />
            <Route
              path="/users/inactive"
              element={<UsersPage type="user" active={false} />}
            />
            <Route path="/users/add" element={<UserAddPage />} />
            <Route path="/users/edit/:id" element={<UserEditPage />} />
          </Route>

          <Route path="/gamers">
            <Route path="/gamers" element={<UsersPage type="gamer" />} />
            <Route
              path="/gamers/inactive"
              element={<UsersPage type="gamer" active={false} />}
            />
            <Route path="/gamers/pricing" element={<GamerPricingPage />} />
          </Route>

          <Route path="/admin">
            <Route path="/admin" element={<UsersPage type="admin" />} />
            <Route
              path="/admin/inactive"
              element={<UsersPage type="adminx" active={false} />}
            />
          </Route>

          <Route path="/games">
            <Route path="/games" element={<GamesPage />} />
            <Route path="/games/add" element={<GameAddPage />} />
            <Route path="/games/edit/:id" element={<GameEditPage />} />
          </Route>

          <Route path="/platforms">
            <Route path="/platforms" element={<PlatformPage />} />
            <Route path="/platforms/add" element={<PlatformAddPage />} />
            <Route path="/platforms/edit/:id" element={<PlatformEditPage />} />
          </Route>
          {/* Payout Requests */}
          <Route path="/payout-requests">
            <Route
              path="/payout-requests"
              element={<PayoutRequestIndexPage />}
            />
            <Route
              path="/payout-requests/pending"
              element={<PayoutRequestPendingPage />}
            />

            <Route
              path="/payout-requests/edit/:id"
              element={<PayoutRequestEditPage />}
            />
          </Route>
          {/* Reported Issues */}
          <Route path="/reported-issues">
            <Route
              path="/reported-issues"
              element={<ReportedIssuesIndexPage />}
            />
            <Route
              path="/reported-issues/pending"
              element={<ReportedIssuesPendingPage />}
            />
            <Route
              path="/reported-issues/edit/:id"
              element={<ReportedIssueEditPage />}
            />
          </Route>
          {/* Canceled Sessions */}
          <Route path="/canceled-sessions">
            <Route
              path="/canceled-sessions"
              element={<CanceledSessionIndexPage />}
            />
            <Route
              path="/canceled-sessions/edit/:id"
              element={<CanceledSessionEditPage />}
            />
          </Route>
          {/* Rate Sessions */}
          <Route path="/rate-sessions">
            <Route path="/rate-sessions" element={<RateSessionsPage />} />
            <Route
              path="/rate-sessions/pending"
              element={<RateSessionPendingPage />}
            />
            <Route
              path="/rate-sessions/edit/:id"
              element={<RateSessionEditPage />}
            />
          </Route>
          {/* Suggestions */}
          <Route path="/suggestions">
            <Route path="/suggestions" element={<SuggestionIndexPage />} />
            <Route
              path="/suggestions/edit/:id"
              element={<SuggestionEditPage />}
            />
          </Route>
        </Route>
      )}

      <Route path="*" element={<Navigate to={authUser ? "/" : "/login"} />} />
    </Routes>
  );
};
