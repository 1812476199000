import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Alert, CircularProgress } from "@mui/material";

import GamerPricingDataGrid from "~/Components/Gamer/GamerPricingDataGrid";
import { GAMER_FIND_BY_TYPE } from "~/Queries/Gamer/Gamer.query";
import { useTitle } from "~/Context/TitleContext";

export default function GamerPricingPage() {
  const { loading, error, data } = useQuery(GAMER_FIND_BY_TYPE);
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Gamers / Pricing    ");
  }, [setTitle]);
  
  if (loading) {
    return <CircularProgress />;
  }
  if (error) {
    return (
      <Alert severity="error">
        {error.message
          ? error.message
          : "Oops! An error has ocurred...Try again later!"}
      </Alert>
    );
  }

  return <GamerPricingDataGrid data={data.userFindByType} />;
}
