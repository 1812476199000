import React from 'react';

import { DataGrid, GridToolbar, GridActionsCellItem, GridColumns, GridRowParams, GridValueGetterParams } from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';
import { Suggestion } from '~/Models/Suggestion.model';
import { useApolloClient } from '@apollo/client';
import { SUGGESTION_DELETE } from '~/Queries/Suggestion/Suggestion.query';
import { useSnackbar } from 'notistack';








interface SuggestionDataGridProps {
    data: Array<Suggestion>,
    onDeleteItem?: () => void
}

export default function SuggestionDataGrid({ data, onDeleteItem }: SuggestionDataGridProps) {

    // Navigation
    const navigate = useNavigate();
    // Client
    const client = useApolloClient();
    // Snack 
    const { enqueueSnackbar } = useSnackbar();

    // On Click Edit
    const onClickEdit = (params: GridRowParams) => {
        navigate(`/suggestions/edit/${params.id}`);

    }
    // On Click Delete
    const onClickDelete = async (params: GridRowParams) => {
        if (window.confirm("Do you really want to delete this item?")) {

            const response = await client.mutate(
                {
                    mutation: SUGGESTION_DELETE,
                    variables: { id: params.id }
                }
            )

            const suggestion = response.data.suggestionDelete || null;

            if (suggestion) {
                if (onDeleteItem) {
                    onDeleteItem();
                }
                enqueueSnackbar('The item has been deleted!', { variant: 'success' });
            } else {
                enqueueSnackbar('Error, please try again!', { variant: 'error' });
            }
        }
    }
    // Columns
    const headCells: GridColumns = [
        { field: '_id', headerName: 'Id', hide: true },
        {
            field: 'username', headerName: 'Username',
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.user?.name}`,
        },
        {
            field: 'fullName', headerName: 'Full name',
            width: 180,
            valueGetter: (params: GridValueGetterParams) =>
                `${params.row.user?.first_name || ''} ${params.row.user?.last_name || ''}`,
        },
        { field: 'suggestion', headerName: 'Suggestion', width: 200 },
        { field: 'created_at', headerName: 'Created', width: 180 },
        {
            field: 'actions',
            type: 'actions',
            headerName: 'Actions',
            width: 100,
            getActions: (params: GridRowParams) => [
                <GridActionsCellItem icon={<EditIcon />} label="Edit" onClick={() => onClickEdit(params)} />,
                <GridActionsCellItem icon={<DeleteIcon />} label="Delete" onClick={() => onClickDelete(params)} />
            ],
        }
    ];


    return (
        <div style={{ height: '70vh', width: '100%' }}>

            <DataGrid

                getRowId={(row) => row._id}
                rows={data}
                columns={
                    headCells
                }
                components={{
                    Toolbar: GridToolbar,
                }}
            ></DataGrid>
        </div>
    );
}