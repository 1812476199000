import gql from 'graphql-tag';

export const SUGGESTION_FIELDS = gql`
  fragment SuggestionFields on Suggestion {
    _id
    user_id
    user {
        avatar_sku
        name
        email
        first_name
        last_name
        avatar_sku
        created_at
    }
    suggestion
    active
    created_at
    updated_at
  }
`;
