import { useQuery } from "@apollo/client";
import { Alert, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import SuggestionForm from "~/Components/Suggestion/SuggestionForm";
import { useTitle } from "~/Context/TitleContext";
import { Suggestion } from "~/Models/Suggestion.model";
import { SUGGESTION_FIND_BY_ID } from "~/Queries/Suggestion/Suggestion.query";

const SuggestionEditPage = () => {
  const { id } = useParams();

  const { data, loading, error } = useQuery(SUGGESTION_FIND_BY_ID, {
    variables: { id: id },
  });

  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Suggestions / Edit");
  }, [setTitle]);

  if (error) {
    return (
      <Alert severity="error">
        {error.message
          ? error.message
          : "Oops! An error has ocurred...Try again later!"}
      </Alert>
    );
  }
  return loading ? (
    <CircularProgress />
  ) : (
    <SuggestionForm data={data.suggestionFindById as Suggestion} />
  );
};

export default SuggestionEditPage;
