import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { BrowserRouter } from "react-router-dom";
import { ApolloProvider } from '@apollo/client';
import { RecoilRoot } from 'recoil';



import theme from '~/Theme/theme'
import Routes from './Routes';
import Client from './Client';
import { SnackbarProvider } from 'notistack';



function Copyright() {
  return (
    <Typography variant="body2" color="text.secondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://mui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}.
    </Typography>
  );
}


export default function App() {
  return (
    <RecoilRoot>
      <ApolloProvider client={Client}>
        <ThemeProvider theme={theme({})}>
          <SnackbarProvider maxSnack={3}>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </SnackbarProvider>
        </ThemeProvider>
      </ApolloProvider>
    </RecoilRoot>
  );
}


