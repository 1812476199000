import gql from 'graphql-tag';

import { SUGGESTION_FIELDS } from './Suggestion.fragment';

//#region Queries
export const SUGGESTION_LIST = gql`
  ${SUGGESTION_FIELDS}
  query suggestionList {
    suggestionList {
      ...SuggestionFields
    }
  }
`;
export const SUGGESTION_FIND_BY_ID = gql`
  ${SUGGESTION_FIELDS}
  query suggestionFindById($id: String!) {
    suggestionFindById(id: $id) {
      ...SuggestionFields
    }
  }
`;
//#endregion Queries

//#region Mutations

export const SUGGESTION_ADD = gql`
  ${SUGGESTION_FIELDS}
  mutation suggestionAdd($data: SuggestionInput!) {
    suggestionAdd(data: $data) {
      ...SuggestionFields
    }
  }
`;

export const SUGGESTION_DELETE = gql`
  ${SUGGESTION_FIELDS}
  mutation suggestionDelete($id: String!) {
    suggestionDelete(id: $id) {
      ...SuggestionFields
    }
  }
`;

//#endregion Mutations
