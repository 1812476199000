import React, { useCallback, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Fab, Box, FormControl, FormHelperText, TextField, Button, Grid, Select, MenuItem, InputLabel } from '@mui/material';
import { useNavigate } from 'react-router-dom';


// third party
import * as Yup from 'yup';
import { Formik } from 'formik';


import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import { USER_ADD, USER_UPDATE } from '~/Queries/Users/User.query';
import { UserInputModel } from '~/Models/User.model';
import { Image } from '~/Models/Image.model';
import ImageInput from './ImageInput';
import { useSnackbar } from 'notistack';





const fabStyle = {
    position: 'absolute',
    bottom: 16,
    right: 16,
    zIndex: 1000
};

// const Input = styled('input')({
//     display: 'none',
// });

// interface State {
//     title: string;
//     subtitle: string;
//     file: File | null;
//     previewFileData?: string | null;
//     button_text?: string;
//     subtitle_color?: string;
//     is_internal?: boolean;
//     link?: string;

// }

interface UserFormInterface {
    id?: string | null;
    data?: UserInputModel & { images: Image[], covers: Image[], file_image?: File | null, file_cover?: File | null }
}



export default function ProfileForm({ id, data = {
    name: "",
    first_name: "",
    last_name: "",
    image: "",
    active: true,
    covers: [],
    images: [],
    created_at: new Date(),
    updated_at: new Date(),
    company_name: '',
    password: '',
    url: '',
    email: '',
    type: ['user'],
    about_me: '',
    phone: '',
    sex: ''
} }: UserFormInterface) {
    // Snack Bar
    const { enqueueSnackbar } = useSnackbar();
    // Navigation
    const navigate = useNavigate();
    // Validation
    const validationSchema = Yup.object().shape({
        name: Yup.string().max(255).required('Name is empty'),
        email: Yup.string().email("Invalid email format").max(255).required('Email is empty'),
        first_name: Yup.string().max(255).required('First Name is empty'),
        last_name: Yup.string().max(255).required('Last Name is empty'),
        password: id ? Yup.string() : Yup.string().max(255).required('Password is empty'),
    })
    // Client
    const client = useApolloClient();
    // Images
    const [profileImage, setProfileImage] = useState<File | null>(null)
    const [coverImage, setCoverImage] = useState<File | null>(null)



    const onSubmit = async (values: any, { setErrors, setStatus, setSubmitting }: any) => {
        try {

            let result = new UserInputModel();
            result = Object.keys(result).reduce((result: any, fieldName) => {

                const value = values[fieldName];
                if (value != null) {
                    result[fieldName] = values[fieldName]
                }

                return result;
            }, result)
            console.log({ result })
            let saved = false;
            if (id) {
                if (result.password && result.password.length === 0) {
                    delete result.password;
                }
                const response = await client.mutate({
                    mutation: USER_UPDATE,
                    variables: {
                        id,
                        data: result,
                        profile_image: profileImage,
                        cover_image: coverImage
                    }
                });
                saved = response.data.userUpdate;
            } else {
                const response = await client.mutate({
                    mutation: USER_ADD,
                    variables: {
                        data: result,
                        profile_image: profileImage,
                        cover_image: coverImage
                    }
                });
                saved = response.data.userAdd;
                console.log({ saved });
            }
            if (saved) {
                await setStatus({ success: true });
                await setSubmitting(false);
                enqueueSnackbar(`User ${id ? 'updated' : 'created'} successfully`, { variant: 'success' });

                navigate(-1)
            } else {
                setStatus({ success: false });
                setErrors({ submit: 'Error submiting the item. Please try again.' });
                enqueueSnackbar('Error submiting the item. Please try again.', { variant: 'error' });
                setSubmitting(false);
            }
        }
        catch (err: any) {
            console.log({ err })
            setStatus({ success: false });
            setErrors({ submit: err.message });
            enqueueSnackbar(err.message, { variant: 'error' });
            setSubmitting(false);

        }


        setStatus({ success: false });
        setSubmitting(false);

    }

    // Change Photo
    const onChangeProfileImage = useCallback((
        file: File) => {

        if (file) {
            setProfileImage(file)
        }
    }, [])
    const onChangeCoverImage = useCallback((
        file: File) => {

        if (file) {
            setCoverImage(file)
        }
    }, [])




    return (
        <Box>


            <Formik
                initialValues={data}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
            >
                {({ errors, handleBlur, setFieldValue, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                    <form noValidate onSubmit={handleSubmit}>
                        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                            <Grid container spacing={2}>
                                {/* Profile Photo */}
                                <Grid item md={6} xs={12}>
                                    <ImageInput onChange={(file) => onChangeProfileImage(file)} images={values.images} buttonText='Change profile image' />
                                </Grid>
                                {/* Cover */}
                                <Grid item md={6} xs={12}>
                                    {values.type && values.type.length > 0 && values.type.includes('gamer') &&
                                        <ImageInput onChange={(file) => onChangeCoverImage(file)} images={values.covers} buttonText='Change cover image' />}
                                </Grid>
                                {/* Username */}
                                <Grid item md={6} xs={12} >
                                    <FormControl fullWidth margin="dense" >

                                        <TextField
                                            label="Username"
                                            name="name"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.name}
                                        />

                                        {touched.name && errors.name && (
                                            <FormHelperText error >
                                                {errors.name}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                {/* Email */}
                                <Grid item md={6} xs={12} >
                                    <FormControl fullWidth margin="dense">

                                        <TextField
                                            label="Email"
                                            name="email"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            value={values.email}
                                        />
                                        {touched.email && errors.email && (
                                            <FormHelperText error id="standard-weight-helper-text-email-login">
                                                {errors.email}
                                            </FormHelperText>
                                        )}
                                    </FormControl>


                                </Grid>
                                {/* First Name */}
                                <Grid item md={12} xs={12} >
                                    <FormControl fullWidth margin="dense">
                                        <TextField
                                            label="First name"
                                            name="first_name"
                                            value={values.first_name}
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.first_name && errors.first_name && (
                                            <FormHelperText error >
                                                {errors.first_name}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                {/* Last Name */}
                                <Grid item md={12} xs={12} >
                                    <FormControl fullWidth margin="dense">
                                        <TextField
                                            label="Last name"
                                            name="last_name"
                                            value={values.last_name}
                                            fullWidth
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.last_name && errors.last_name && (
                                            <FormHelperText error >
                                                {errors.last_name}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                {/* Password */}
                                <Grid item md={12} xs={12}>
                                    <FormControl fullWidth margin="dense">
                                        <TextField
                                            label={id ? "Password (Write to change the current one)" : "Password"}
                                            name="password"
                                            fullWidth
                                            value={values.password}
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                        />
                                        {touched.password && errors.password && (
                                            <FormHelperText error >
                                                {errors.password}
                                            </FormHelperText>
                                        )}
                                    </FormControl>
                                </Grid>
                                <Grid item md={12} xs={12}>
                                    <FormControl fullWidth margin="dense">
                                        <InputLabel id="type-label">Types</InputLabel>
                                        <Select
                                            labelId="type-label"
                                            id="type-select"
                                            name="type"
                                            value={values.type}
                                            label="Types"
                                            onBlur={handleBlur}
                                            onChange={handleChange}
                                            multiple={true}
                                        >
                                            <MenuItem value={"user"}>User</MenuItem>
                                            <MenuItem value={"gamer"}>Gamer</MenuItem>
                                            <MenuItem value={"admin"}>Admin</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Box>


                        <Box sx={{ mt: 2 }}>
                            <FormControl fullWidth margin="dense">
                                <Button
                                    disableElevation
                                    disabled={isSubmitting}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                >
                                    Submit
                                </Button>
                            </FormControl>
                        </Box>
                        <Fab color="primary" aria-label="add" sx={fabStyle} onClick={() => navigate('/home-carousel-items')}>
                            <NavigateBeforeIcon />
                        </Fab>

                    </form>
                )
                }
            </Formik >
        </Box >
    );
}
