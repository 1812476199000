import { useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import GameForm from "~/Components/GameForm";
import { useTitle } from "~/Context/TitleContext";
import { GAME_FIND_BY_ID } from "~/Queries/Games/Game.query";

export default () => {
  const { id } = useParams();

  const { data, loading, error } = useQuery(GAME_FIND_BY_ID, {
    variables: { game_id: id },
  });

  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Games / Edit");
  }, [setTitle]);

  return loading ? (
    <CircularProgress />
  ) : (
    <GameForm id={id} data={data.gameFindById} />
  );
};
