import gql from 'graphql-tag';

import { PAYOUT_REQUEST_FIELDS } from './PayoutRequest.fragment';
// Queries
export const PAYOUT_REQUEST_ADMIN_LIST = gql`
  ${PAYOUT_REQUEST_FIELDS}
  query payoutRequestAdminList {
    payoutRequestAdminList {
      ...PayoutRequestFields
    }
  }
`;

export const PAYOUT_REQUEST_ADMIN_LIST_BY_STATUS = gql`
  ${PAYOUT_REQUEST_FIELDS}
  query payoutRequestAdminListByStatus($status: PayoutRequestStatus!) {
    payoutRequestAdminListByStatus(status: $status){
      ...PayoutRequestFields
    }
  }
`;
export const PAYOUT_REQUEST_FIND_BY_ID = gql`
  ${PAYOUT_REQUEST_FIELDS}
  query payoutRequestFindById($id: String!) {
    payoutRequestFindById(id: $id){
      ...PayoutRequestFields
    }
  }
`;

// Mutations
export const PAYOUT_REQUEST_ADMIN_UPDATE = gql`
  ${PAYOUT_REQUEST_FIELDS}
  mutation payoutRequestAdminUpdate($id: String!,$data: PayoutRequestInput ! ) {
    payoutRequestAdminUpdate(id: $id, data: $data) {
      ...PayoutRequestFields
    }
  }
`;

