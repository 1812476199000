import { useQuery } from "@apollo/client";
import { Alert, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import ReportedIssueForm from "~/Components/ReportedIssue/ReportedIssueForm";
import { useTitle } from "~/Context/TitleContext";
import { ReportedIssue } from "~/Models/ReportedIssue.model";
import { REPORTED_ISSUE_FIND_BY_ID } from "~/Queries/ReportIssue/ReportedIssue.query";

const ReportedIssueEditPage = () => {
  const { id } = useParams();

  const { data, loading, error, refetch } = useQuery(
    REPORTED_ISSUE_FIND_BY_ID,
    {
      variables: { id: id },
    }
  );

  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Reported Issues / Edit");
  }, [setTitle]);

  const onRefetch = () => {
    refetch();
  };
  if (error) {
    return (
      <Alert severity="error">
        {error.message
          ? error.message
          : "Oops! An error has ocurred...Try again later!"}
      </Alert>
    );
  }
  return loading ? (
    <CircularProgress />
  ) : (
    <ReportedIssueForm
      refetch={onRefetch}
      data={data.reportedIssueFindById as ReportedIssue}
    />
  );
};

export default ReportedIssueEditPage;
