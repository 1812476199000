import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Alert, CircularProgress } from "@mui/material";

import { REPORTED_ISSUE_LIST_PENDING } from "~/Queries/ReportIssue/ReportedIssue.query";
import ReportedIssueDataGrid from "~/Components/ReportedIssue/ReportedIssueDataGrid";
import { useTitle } from "~/Context/TitleContext";

export default function ReportedIssuesPendingPage() {
  const { loading, error, data } = useQuery(REPORTED_ISSUE_LIST_PENDING);

  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Reported Issues / Pending");
  }, [setTitle]);

  if (loading) {
    return <CircularProgress />;
  }
  if (error) {
    return (
      <Alert severity="error">
        {error.message
          ? error.message
          : "Oops! An error has ocurred...Try again later!"}
      </Alert>
    );
  }

  return <ReportedIssueDataGrid data={data.reportedIssueListPending} />;
}
