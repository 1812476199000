import { Checkbox, FormControl, FormControlLabel, FormHelperText, Grid, TextField } from "@mui/material";
import Form from "~/Components/Form";

import { PLATFORM_ADD, PLATFORM_UPDATE } from "~/Queries/Platform/Platform.query";

import * as Yup from 'yup';

class PlatformInputModel {
    name!: string;
    active?: boolean = true;
}

const defaultData: PlatformInputModel = {
    name: "",
    active: true
}
interface PlatformFormInterface {
    id?: string | null;
    data?: any,
}

export default function PlatformForm(props: PlatformFormInterface) {
    const validationSchema = Yup.object().shape({
        name: Yup.string().required('Name is empty'),
    })
    return (
        <Form validationSchema={validationSchema} model={PlatformInputModel} createMutation={PLATFORM_ADD} updateMutation={PLATFORM_UPDATE} backLink="/platforms" data={props.data || defaultData} id={props.id}>
            {({ errors, handleBlur, setFieldValue, handleChange, handleSubmit, isSubmitting, touched, values }) => (
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12} >
                        <FormControl fullWidth margin="dense">

                            <TextField
                                label="Name"
                                name="name"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.name}
                            />
                            {touched.name && errors.name && (
                                <FormHelperText error >
                                    {errors.name}
                                </FormHelperText>
                            )}
                        </FormControl>

                    </Grid>
                    <Grid item md={12} xs={12} >
                        <FormControl fullWidth margin="dense">
                            <FormControlLabel control={<Checkbox defaultChecked
                                name="active"
                                onBlur={handleBlur}
                                onChange={handleChange}
                                value={values.active}
                            />} label="Active" />
                        </FormControl>
                    </Grid>


                </Grid>

            )
            }
        </Form >
    )
}