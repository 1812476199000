import gql from "graphql-tag";

import { CANCELED_SESSION_FIELDS } from "./CanceledSession.fragment";
//#region Queries

//#endregion Queries

export const CANCELED_SESSION_LIST = gql`
  ${CANCELED_SESSION_FIELDS}
  query canceledSessionList($status: String) {
    canceledSessionList(status: $status) {
      ...CanceledSessionFields
      gamer {
        name
        display_name
        first_name
        last_name
        type
        avatar_sku
        images {
          original
          sm
          md
          lg
        }
        covers {
          original
          cover
        }
      }
      canceled_by_user {
        name
        display_name
        first_name
        last_name
        type
        avatar_sku
        images {
          original
          sm
          md
          lg
        }
        covers {
          original
          cover
        }
      }
      user {
        name
        display_name
        first_name
        last_name
        type
        avatar_sku
      }
    }
  }
`;

export const CANCELED_SESSION_UPDATE = gql`
  ${CANCELED_SESSION_FIELDS}
  mutation canceledSessionUpdate($id: String!, $data: CanceledSessionInput!) {
    canceledSessionUpdate(id: $id, data: $data) {
      ...CanceledSessionFields
      gamer {
        name
        display_name
        first_name
        last_name
        type
        avatar_sku
        images {
          original
          sm
          md
          lg
        }
        covers {
          original
          cover
        }
      }
      canceled_by_user {
        name
        display_name
        first_name
        last_name
        type
        avatar_sku
        images {
          original
          sm
          md
          lg
        }
        covers {
          original
          cover
        }
      }
      user {
        name
        display_name
        first_name
        last_name
        type
        avatar_sku
      }
    }
  }
`;

export const CANCELED_SESSION_FIND_BY_ID = gql`
  ${CANCELED_SESSION_FIELDS}
  query canceledSessionFindById($id: String!) {
    canceledSessionFindById(id: $id) {
      ...CanceledSessionFields
      gamer {
        name
        display_name
        first_name
        last_name
        type
        avatar_sku
        images {
          original
          sm
          md
          lg
        }
        covers {
          original
          cover
        }
      }
      canceled_by_user {
        name
        display_name
        first_name
        last_name
        type
        avatar_sku
        images {
          original
          sm
          md
          lg
        }
        covers {
          original
          cover
        }
      }
      user {
        name
        display_name
        first_name
        last_name
        type
        avatar_sku
      }
    }
  }
`;

/*
export const CANCELED_SESSION_LIST_PENDING = gql`
  ${CANCELED_SESSION_FIELDS}
  query canceledSessionListPending{
    canceledSessionListPending{
      ...CanceledSessionFields
    }
  }
`;



//#region Mutations
export const CANCELED_SESSION_ADD = gql`
  ${CANCELED_SESSION_FIELDS}
  mutation canceledSessionAdd($data: CanceledSessionInput!) {
    canceledSessionAdd(data: $data) {
      ...CanceledSessionFields
    }
  }
`;
export const CANCELED_SESSION_UPDATE = gql`
  ${CANCELED_SESSION_FIELDS}
  mutation canceledSessionUpdate($id: String!, $data: CanceledSessionInput!) {
    canceledSessionUpdate(id: $id,data: $data) {
      ...CanceledSessionFields
    }
  }
`;





//#endregion Mutations
*/
