import { useQuery } from "@apollo/client";
import { CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import PlatformForm from "~/Components/PlatformForm";
import { useTitle } from "~/Context/TitleContext";
import { PLATFORM_FIND_BY_ID } from "~/Queries/Platform/Platform.query";

export default () => {
  const { id } = useParams();

  const { data, loading, error } = useQuery(PLATFORM_FIND_BY_ID, {
    variables: { id },
  });

  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Platforms / Edit");
  }, [setTitle]);

  return loading ? (
    <CircularProgress />
  ) : (
    <PlatformForm id={id} data={data.platformFindById} />
  );
};
