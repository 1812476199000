import { BaseModel } from '~/Models/Base.model';
import { Issue } from './Issue.model';
import { Session } from './Session.model';
import { User } from './User.model';

export enum ReportedIssueStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  RESOLVED = 'RESOLVED'
}


export class ReportedIssue extends BaseModel {

  issue_id!: String;
  status: ReportedIssueStatus = ReportedIssueStatus.PENDING;
  user_id!: String;
  id_reference!: String;
  description!: String;
  active!: boolean;

  issue: Issue = new Issue();
  user: User = new User();
  session: Session = new Session();
}
