import React, { useState } from "react";

const TitleContext = React.createContext({
  title: "OWrlee",
  setTitle: (title: string) => {},
});

export const useTitle = () => React.useContext(TitleContext);

const TitleProvider = ({ children }: { children: React.ReactNode }) => {
  const [title, setTitle] = useState("OWrlee");

  return (
    <TitleContext.Provider value={{ title, setTitle }}>
      {children}
    </TitleContext.Provider>
  );
};

export default TitleProvider;
