import { BaseModel } from '~/Models/Base.model';

export enum IssueType {
  APP = 'APP',
  APPOINTMENT = 'APPOINTMENT'
}
export class Issue extends BaseModel {
   
   type: IssueType = IssueType.APP;
   
  
   name!: String;
 
  
   order!: number;
 
}
