import { BaseModel } from '~/Models/Base.model';
import { User } from './User.model';

export enum PayoutRequestStatus {
  PENDING = 'PENDING',
  PROCESSING = 'PROCESSING',
  INVALID = 'INVALID',
  CONFIRMED = 'CONFIRMED'
}

export class PayoutRequest extends BaseModel {
  payment_id!: string;

  user_id!: string;

  amount = 0;
  decimal_amount = 0;
  status: PayoutRequestStatus = PayoutRequestStatus.PENDING;

  user = new User();

  reason ? : string;
}
