import React, { useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Alert, CircularProgress } from '@mui/material';
import PayoutRequestDataGrid from '~/Components/PayoutRequest/PayoutRequestDataGrid';
import { PAYOUT_REQUEST_ADMIN_LIST_BY_STATUS } from '~/Queries/PayoutRequest/PayoutRequest.query';
import { PayoutRequestStatus } from '~/Models/PayoutRequest.model';
import { useTitle } from '~/Context/TitleContext';



export default function PayoutRequestPendingPage() {
    const { loading, error, data } = useQuery(PAYOUT_REQUEST_ADMIN_LIST_BY_STATUS, {
        variables: { status: PayoutRequestStatus.PENDING }
    });

    const { setTitle } = useTitle();

    useEffect(() => {
      setTitle("Payouts / Pending");
    }, [setTitle]);


    if (loading) {
        return <CircularProgress />
    }
    if (error) {
        return <Alert severity="error">{error.message ? error.message : 'Oops! An error has ocurred...Try again later!'}</Alert>

    }
    return (
        <PayoutRequestDataGrid data={data.payoutRequestAdminListByStatus} />
    );
}


