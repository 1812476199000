export const APP_MOBILE_URLS = [
    {
        name: 'My Appointments',
        value: 'MY_APPOINTMENTS'
    },
    {
        name: 'My Profile',
        value: 'MY_PROFILE'
    },
    {
        name: 'Gamers',
        value: 'GAMERS'
    },
    {
        name: 'Games',
        value: 'GAMES'
    },
    {
        name: 'Search',
        value: 'SEARCH'
    },
];