import { useQuery } from "@apollo/client";
import { Alert, CircularProgress } from "@mui/material";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import HomeCarouselItemForm from "~/Components/HomeCarousel/HomeCarouselItemForm";
import { useTitle } from "~/Context/TitleContext";
import { HOME_CAROUSEL_ITEM_FIND_BY_ID } from "~/Queries/HomeCarouselItems/HomeCarouselItems.query";

const HomeCarouselItemEditPage = () => {
  const { id } = useParams();

  const { data, loading, error } = useQuery(HOME_CAROUSEL_ITEM_FIND_BY_ID, {
    variables: { id },
  });

  const { setTitle } = useTitle();
  useEffect(() => {
    setTitle("Home Carousel / Edit");
  }, [setTitle]);

  if (error) {
    return (
      <Alert severity="error">
        {error.message
          ? error.message
          : "Oops! An error has ocurred...Try again later!"}
      </Alert>
    );
  }
  return loading ? (
    <CircularProgress />
  ) : (
    <HomeCarouselItemForm id={id} data={data.homeCarouselItemFindById} />
  );
};

export default HomeCarouselItemEditPage;
