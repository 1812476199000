import { useEffect } from "react";
import PlatformForm from "~/Components/PlatformForm";
import { useTitle } from "~/Context/TitleContext";

export default () => {
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Platforms / Add");
  }, [setTitle]);
  return <PlatformForm />;
};
