import gql from 'graphql-tag';

export const CANCELED_SESSION_FIELDS = gql`
  fragment CanceledSessionFields on CanceledSession {
    _id
    user_id
    gamer_id
    game_id
    start_date
    end_date
    created_at
    updated_at
    session_type
    status
    canceled_by_type
    canceled_by
  }
`;
