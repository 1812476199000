import { useEffect } from "react";
import UserForm from "~/Components/UserForm";
import { useTitle } from "~/Context/TitleContext";


const UserAddPage = () => {

    const { setTitle } = useTitle();
    useEffect(() => {
      setTitle("Users / Add");
    }, [setTitle]);

    return (
        <UserForm />
    )
}
export default UserAddPage;