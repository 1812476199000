import { useEffect } from "react";
import HomeCarouselItemForm from "~/Components/HomeCarousel/HomeCarouselItemForm";
import { useTitle } from "~/Context/TitleContext";

const HomeCarouselItemAddPage = () => {
  const { setTitle } = useTitle();
  useEffect(() => {
    setTitle("Home Carousel / Add");
  }, [setTitle]);

  return <HomeCarouselItemForm />;
};

export default HomeCarouselItemAddPage;
