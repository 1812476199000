import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Alert, CircularProgress } from "@mui/material";
import { SUGGESTION_LIST } from "~/Queries/Suggestion/Suggestion.query";
import SuggestionDataGrid from "~/Components/Suggestion/SuggestionDataGrid";
import { useTitle } from "~/Context/TitleContext";

export default function SuggestionIndexPage() {
  const { loading, error, data, refetch } = useQuery(SUGGESTION_LIST);

  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Suggestions");
  }, [setTitle]);

  const onDeleteItem = () => {
    refetch();
  };
  if (loading) {
    return <CircularProgress />;
  }
  if (error) {
    return (
      <Alert severity="error">
        {error.message
          ? error.message
          : "Oops! An error has ocurred...Try again later!"}
      </Alert>
    );
  }
  return (
    <SuggestionDataGrid
      data={data.suggestionList}
      onDeleteItem={onDeleteItem}
    />
  );
}
